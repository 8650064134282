import React, { useEffect, useState } from "react";
import "./login.css";
import educafilxlogo from "../../images/logo-wh.png";
import { FormGroup, Form, Input, Alert } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import api from "../../services/api";
import PulseLoader from "react-spinners/PulseLoader";

const Login = () => {
  const [values, setValues] = useState([]);
  const [post, setPost] = useState(null);
  const [logado, setLogado] = useState(false);
  const [logadomsg, setLogadomsg] = useState("");
  const history = useHistory();
  const [isloading, setIsloading] = useState(false);

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);

  function onChange(ev){
    const {name, value} = ev.target;
    setValues({ ...values, [name]: value });
  }

  useEffect(() => {
    const id = localStorage.getItem("@educa-app/id");
    if (id !== null){
      window.location.href ='/aluno';
    }

  }, []);

  async function onSubmit(ev){
    ev.preventDefault();
    setIsloading(true);
    const dados =  await api.get(`/loginrest?username=${values.username}&password=${values.password}`).then((res) => {
      const result = res.data;
      console.log(result);
      if (result.status_code == "400") {
        setIsloading(false);
        setLogado(false);
        setVisible(true);
        setLogadomsg(result.dados.error.msg);
      } else {
        setLogado(true);
        setVisible(false);
        localStorage.setItem('@educa-app/id', result.dados.user_id);
        localStorage.setItem('@educa-app/nome', result.dados.first_name);
        localStorage.setItem('@educa-app/sobrenome', result.dados.last_name);
        localStorage.setItem('@educa-app/email', result.dados.email);
        localStorage.setItem('@educa-app/token', result.token);
        localStorage.setItem('@educa-app/foto', result.dados.image);
        setIsloading(false);
        //history.push('/aluno');
        window.location.href ='/aluno';
      }
    }).catch(error => {
        setPost(false);
        setVisible(true);
        setLogado(false);
        setLogadomsg('Não foi possível realizar o login, tente novamente mais tarde!');
    });
  }

  return (
    <>
      <section class="sign-in-page">
        <div id="menu">
          <div id="left">
            <Link to="/">  
                <img src={educafilxlogo} alt="logo" />
            </Link>
          </div>
          <div id="right">
            {/* <Link to="" className="registro-link-menu">Sair</Link> */}
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-center align-items-center height-self-center">
            <div class="col-lg-5 col-md-12 align-self-center">
              <div class="sign-user_card ">
                <div class="sign-in-page-data">
                  <div class="sign-in-from w-100 m-auto">
                    <h3 class="mb-3 text-center">Entrar</h3>
                    { logado == true ? '' 
                      : <Alert color="danger" isOpen={visible} toggle={onDismiss}>
                          {logadomsg}
                        </Alert>
                    }
                    
                    <Form onSubmit={onSubmit} class="mt-4">
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          id="username"
                          name="username"
                          placeholder="Email ou número de telefone"
                          type="email"
                          // tag={InputMask}
                          onChange={onChange}
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          id="password"
                          name="password"
                          placeholder="Senha"
                          type="password"
                          // tag={InputMask}
                          onChange={onChange}
                          required
                        />
                      </FormGroup>
                      <div class="sign-info">
                        <button type="submit" className="btn btn-hover  my-2" style={{ width: "100%" }} >
                          {isloading == false ?
                              "Entrar"
                            : 
                              <PulseLoader color={"#fff"} loading={true} size={5} />
                          }
                        </button>
                        
                        {/* <div class="custom-control custom-checkbox d-inline-block">
                          <Input
                            type="checkbox"
                            class="custom-control-input"
                            id="customCheck"
                          />
                          <span class="custom-control-label" for="customCheck">
                            Lembre-se de mim
                          </span>
                        </div> */}
                      </div>
                    </Form>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="d-flex justify-content-center links">
                    <Link to="/recuperar" class="f-link">
                      Esqueceu sua senha?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
