import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import "./perfil.css";
import Topo from "../../components/Topo/Topo";
import Rodape from "../../components/Rodape/Rodape";
import imguser from "../../images/perfil/avatar-no-picture.png";
import { Form, Input, Label, Alert } from "reactstrap";
import PulseLoader from "react-spinners/PulseLoader";
import api from "../../services/api";
import { urlbase } from "../../services/Params";

const Perfil = () => {
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [email, setEmail] = useState('');
  const [sexo, setSexo] = useState('');
  const [dtnasc, setDtnasc] = useState('');
  const [cep, setCep] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cpf, setCpf] = useState('');
  const [senha, setSenha] = useState('');
  const [escorigem, setEscorigem] = useState('');
  const [cursos, setCursos] = useState('');
  const [imagem, setImagem] = useState(imguser);
  const [retorno, setRetorno] = useState(false);
  const [retornostatus, setRetornostatus] = useState(200);
  const [retornomsg, setRetornomsg] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const onDismiss = () => setVisible(false);

  const filesElement = useRef(null);

  useEffect(() => {
    const id = localStorage.getItem("@educa-app/id");
    const token = localStorage.getItem("@educa-app/token");
    if (id == null) {
      window.location.href = "/";
    } else {
      async function getPessoa(){
        const dados = await api.get(`/userdatarest?auth_token=${token}`).then((res) => {
          const result = res.data;
          if (result.status_code == "400") {
            //console.log(result);
            //setLogadomsg(result.dados.error.msg);
          } else {
            console.log(result);
            setNome(result.dados.first_name);
            setSobrenome(result.dados.last_name);
            setEmail(result.dados.email);
            setDtnasc(result.dados.datanascimento);
            setSexo(result.dados.sexo);
            setCep(result.dados.cep);
            setEscorigem(result.dados.escolaorigem);
            setCursos(result.dados.cursospretendidos);
            setCpf(result.dados.cpf);
            setTelefone(result.dados.telefone)
            setImagem(result.dados.image);
          }
        }).catch(error => {
          console.log('Não foi possível carregar as informações do usuário');
        });
      }
      getPessoa();
    }
  }, []);

  const sendFile = async () => {
    const dataForm = new FormData();
    
    for (const file of filesElement.current.files) {
      dataForm.append('auth_token', localStorage.getItem("@educa-app/token"));
      dataForm.append('file', file);
      console.log(file);
    }
    
    const res = await fetch(`${urlbase}/uploaduserimage`, {
      method: 'POST',
      body: dataForm,
    });
    const data = await res.json();
    console.log(data);
    if (data.status !== "success") {
      setIsloading(false);
      setVisible(true);
      setRetorno(false);
      setRetornostatus(400);
      setRetornomsg("Não foi possivel atualizar sua foto de perfil, tente novamente mais tarde.");
    } else {
      setIsloading(false);
      setVisible(true);
      setRetorno(false);
      setRetornostatus(200);
      setRetornomsg("Sua imagem do perfil foi atualizada.");
    }
  };

  function submit(e){
    e.preventDefault()
    const formdata = {
      first_name: nome,
      last_name: sobrenome,
      sexo:sexo,
      dtnasc:dtnasc,
      cep:cep,
      escolaorigem:escorigem,
      cursopretendidos:cursos,
      email:email,
      status:1,
      telefone:telefone,
      cpf:cpf,
      senha:senha,
      auth_token: localStorage.getItem("@educa-app/token")
    }
    setIsloading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formdata),
    };
    fetch(`${urlbase}/updateuserdatarest`, requestOptions)
    .then(
      response => response.json()
    )
    .then(
      result => { 
        if (result.status_code == "400") {
          setIsloading(false);
          setVisible(true);
          setRetorno(false);
          setRetornostatus(400);
          setRetornomsg("Não foi possivel atualizar seus dados neste momento, tente novamente mais tarde.");
        } else {
          setIsloading(false);
          setVisible(true);
          setRetorno(false);
          setRetornostatus(200);
          setRetornomsg(result.dados.msg);
        }
      }
    )
  }

  return (
    <>
      <Topo />
      <section class="m-profile manage-p">
        <div class="container h-100">
          <div class="row align-items-center justify-content-center h-100">
            <div class="col-lg-10">
              <div class="sign-user_card">
                <div class="row">
                  <div class="col-lg-2">
                    <div class="upload_profile d-inline-block" >
                      <img
                        src={imagem}
                        class="profile-pic rounded-circle img-fluid"Topo
                        alt="user"
                      />
                      <div class="p-image">
                        <i class="ri-pencil-line upload-button"></i>
                        <input
                          class="file-upload"
                          type="file"
                          name="file"
                          accept="image/*"
                          multiple 
                          ref={filesElement}
                          onChange={sendFile}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-10 device-margin">
                    <div class="profile-from">
                        
                      <h4 class="mb-3">Meu Perfil</h4>
                        { retornostatus == 200 ?
                          <Alert color="success" isOpen={visible} toggle={onDismiss}>
                            {retornomsg}
                          </Alert>
                        : 
                          <Alert color="danger" isOpen={visible} toggle={onDismiss}>
                            {retornomsg}
                          </Alert>
                        }
                      <Form onSubmit={(e)=>submit(e)}>
                        <div class="form-group">
                          <label>Nome</label>
                          <Input
                            type="text"
                            class="form-control mb-0"
                            id="nome"
                            name="nome"
                            placeholder="Informe seu nome"
                            autocomplete="off"
                            value={nome}
                            onChange={ (e) => setNome(e.target.value) }
                            required
                          />
                        </div>
                        <div class="form-group">
                          <label>Sobrenome</label>
                          <Input
                            type="text"
                            class="form-control mb-0"
                            id="sobrenome"
                            name="sobrenome"
                            placeholder="Sobrenome"
                            autocomplete="off"
                            value={sobrenome}
                            onChange={ (e) => setSobrenome(e.target.value) }
                            required
                          />
                        </div>
                        <div class="form-group">
                          <label>Email</label>
                          <Input
                            type="email"
                            class="form-control mb-0"
                            id="email"
                            name="email"
                            placeholder="email@educaflix.co"
                            autocomplete="off"
                            value={email}
                            onChange={ (e) => setEmail(e.target.value) }
                            required
                          />
                        </div>
                        <div class="form-group d-flex flex-md-row flex-column">
                          <div class="iq-custom-select d-inline-block manage-gen">
                            <Label for="telefone">Telefone</Label>
                            <Input
                                type="text"
                                class="form-control date-input basicFlatpickr mb-0"
                                placeholder="(00) 0000-0000"
                                id="telefone"
                                name="telefone"
                                value={telefone}
                                onChange={ (e) => setTelefone(e.target.value) }
                                required
                              />
                          </div>
                          <div class="iq-custom-select d-inline-block lang-dropdown manage-dd">
                            <div class="form-group">
                              <label for="cpf">CPF</label>
                              <Input
                                type="text"
                                class="form-control date-input basicFlatpickr mb-0"
                                placeholder="000.000.000-00"
                                id="cpf"
                                name="cpf"
                                value={cpf}
                                onChange={ (e) => setCpf(e.target.value) }
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group d-flex flex-md-row flex-column">
                          <div class="iq-custom-select d-inline-block manage-gen">
                            <Label for="Sexo">Sexo</Label>
                            <Input 
                              type="select" 
                              name="sexo" 
                              id="sexo"
                              class="select-sexo"
                              class="form-control pro-dropdown"
                              value={sexo}
                              onChange={ (e) => setSexo(e.target.value) }
                            >
                              { sexo == "F" ? <option value="M" selected>Feminino</option> : <option value="F">Feminino</option> }
                              { sexo == "M" ? <option value="M" selected>Masculino</option> : <option value="M">Masculino</option> }
                              { sexo == "O" ? <option value="M" selected>Outro</option> : <option value="O">Outro</option> }
                            </Input>
                          </div>
                          <div class="iq-custom-select d-inline-block lang-dropdown manage-dd">
                            <div class="form-group">
                              <label>Data de Nascimento</label>
                              <input 
                                id="dtnasc"
                                name="dtnasc"
                                type="date" 
                                data-date="" 
                                data-date-format="DD MMMM YYYY" 
                                value={dtnasc} 
                                onChange={(e) => setDtnasc(e.target.value)}
                              />
                              {/* <input
                                type="date"
                                id="dtnasc"
                                name="dtnasc"
                                value={dtnasc}
                                onChange={ (e) => setDtnasc(e.target.value) }
                                class="form-control date-input basicFlatpickr mb-0"
                                // time={false}
                              /> */}
                            </div>
                          </div>
                          <div class="iq-custom-select d-inline-block lang-dropdown manage-dd">
                            <div class="form-group">
                              <label>CEP</label>
                              <Input
                                type="text"
                                class="form-control mb-0"
                                placeholder="00000-000"
                                id="cep"
                                name="cep"
                                value={cep}
                                onChange={ (e) => setCep(e.target.value) }
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group d-flex flex-md-row flex-column">
                          <div class="iq-custom-select d-inline-block manage-gen">
                          <label>Senha</label>
                          <Input
                            type="password"
                            class="form-control mb-0"
                            id="senha"
                            name="senha"
                            placeholder="Sua senha"
                            autocomplete="off"
                            value={senha}
                            onChange={ (e) => setSenha(e.target.value) }
                          />
                        </div>
                        </div>
                        <div class="form-group">
                          <label>Escola de Origem</label>
                          <Input
                            type="text"
                            class="form-control mb-0"
                            id="escorigem"
                            name="escorigem"
                            placeholder="Escola de Origem"
                            autocomplete="off"
                            value={escorigem}
                            onChange={ (e) => setEscorigem(e.target.value) }
                            required
                          />
                        </div>
                        <div class="form-group">
                          <label>Curso Pretendido</label>
                          <select
                            id="cursos"
                            name="cursos"
                            class="form-control pro-dropdown"
                            multiple="multiple"
                            onChange={ (e) => setCursos(e.target.value) }
                          >
                            <option value="med">Medicina</option>
                            <option value="farma">Farmácia</option>
                            <option value="enge">Engenharia Elétrica</option>
                            <option value="vet">Medicina Veterinária</option>
                            <option value="fisio">Fisioterapia</option>
                            <option value="odonto">Odontologia</option>
                            <option value="engcivil">Engenharia Civil</option>
                            <option value="letras">Letras / Português</option>
                            <option value="ciencbio">Ciências Biológicas</option>
                            <option value="pisico">Psicologia</option>
                            <option value="arquit">Arquitetura e Urbanismo</option>
                            <option value="cienceco">Ciências Econômicas</option>
                            <option value="jorna">Jornalismo</option>
                            <option value="biomed">Biomedicina</option>
                            <option value="engprod">Engenharia de Produção</option>
                            <option value="rechum">Gestão de Rec. Humanos</option>
                            <option value="nutri">Nutrição</option>
                            <option value="enferm">Enfermagem</option>
                            <option value="public">Publicidade e Propaganda</option>
                            <option value="direito">Direito</option>
                            <option value="comext">Comércio Exterior</option>
                            <option value="servsoc">Serviço Social</option>
                            <option value="market">Marketing</option>
                            <option value="artvisu">Artes Visuais</option>
                            <option value="adm">Administração</option>
                            <option value="gecome">Gestão Comercial</option>
                            <option value="logist">Logística</option>
                            <option value="pedago">Pedagogia</option>
                            <option value="radio">Radiologia</option>
                            <option value="agro">Agronomia</option>
                            <option value="conta">Ciências Contábeis</option>
                            <option value="gastrono">Gastronomia</option>
                            <option value="segtrab">Segurança no Trabalho</option>
                            <option value="edfisic">Educação Física</option>
                            <option value="empre">Empreendedorismo</option>
                            <option value="cienccomp">Ciências da Computação</option>
                            <option value="ciencinfor">Sistemas de Informação</option>
                          </select>
                        </div>
                        <div class="d-flex">
                          <button type="submit" className="btn btn-hover  my-2" style={{ width: "100%" }} >
                            {isloading == false ?
                                "Salvar"
                              : 
                                <PulseLoader color={"#fff"} loading={true} size={5} />
                            }
                          </button>
                          <input type="Reset" className="btn btn-link" color="#" value="Cancelar"/>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <Rodape />
      <div id="back-to-top">
        <a class="top" href="#top" id="top">
          {" "}
          <i class="fa fa-angle-up"></i>{" "}
        </a>
      </div>
    </>
  );
};

export default Perfil;
