import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./configuracoes.css";
import imguser from "../../images/perfil/avatar-no-picture.png";
import Topo from "../../components/Topo/Topo";
import Rodape from "../../components/Rodape/Rodape";
import { Form, Input } from "reactstrap";
import api from "../../services/api";
import { urlbase } from "../../services/Params";
import moment from 'moment';

const Configuracoes = () => {
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [email, setEmail] = useState('');
  const [sexo, setSexo] = useState('');
  const [dtnasc, setDtnasc] = useState('');
  const [cep, setCep] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cpf, setCpf] = useState('');
  const [biografia, setBiografia] = useState('');
  const [senha, setSenha] = useState('');
  const [escorigem, setEscorigem] = useState('');
  const [cursos, setCursos] = useState('');
  const [imagem, setImagem] = useState(imguser);
  const [isloading, setIsloading] = useState(false);
  
  useEffect(() => {
    const id = localStorage.getItem("@educa-app/id");
    const token = localStorage.getItem("@educa-app/token");
    if (id == null) {
      window.location.href = "/";
    } else {
      async function getPessoa(){
        const dados = await api.get(`/userdatarest?auth_token=${token}`).then((res) => {
          setIsloading(true);
          const result = res.data;
          if (result.status_code == "400") {
            //console.log(result);
            //setLogadomsg(result.dados.error.msg);
            setIsloading(false);
          } else {
            setIsloading(false);
            setNome(result.dados.first_name);
            setSobrenome(result.dados.last_name);
            setEmail(result.dados.email);
            setDtnasc(result.dados.datanascimento);
            setSexo(result.dados.sexo);
            setBiografia(result.dados.biography);
            setCep(result.dados.cep);
            setEscorigem(result.dados.escolaorigem);
            setCursos(result.dados.cursospretendidos);
            setCpf(result.dados.cpf);
            setTelefone(result.dados.telefone)
            setImagem(result.dados.image);
          }
        }).catch(error => {
          console.log('Não foi possível carregar as informações do usuário');
        });
      }
      getPessoa();
    }
  }, []);

  return (
    <>
      <Topo />
      <section class="m-profile setting-wrapper">
        <div class="container">
          <h4 class="main-title mb-4">Configurações da Conta</h4>
          <div class="row">
            <div class="col-lg-4 mb-3">
              <div class="sign-user_card text-center">
                <img
                  src={imagem}
                  class="rounded-circle img-fluid d-block mx-auto mb-3"
                  alt="user"
                  style={{ maxWidth: '150px' }}
                />
                <h4 class="mb-3">{nome} {sobrenome}</h4>
                <p>
                  <b>{escorigem}</b>
                </p>
                {/* <a href="#" class="edit-icon text-primary">
                  Edit
                </a> */}
              </div>
            </div>
            <div class="col-lg-8">
              <div class="sign-user_card">
                <h5 class="mb-3 pb-3 a-border">Dados Pessoais</h5>
                <div class="row align-items-center justify-content-between mb-3">
                  <div class="col-md-8">
                    <span class="text-light font-size-13">Email</span>
                    <p class="mb-0">
                      {email}
                    </p>
                  </div>
                  <div class="col-md-4 text-md-right text-left">
                    <Link to="/perfil" class="text-primary">
                      Alterar
                    </Link>
                  </div>
                </div>
                <div class="row align-items-center justify-content-between mb-3">
                  <div class="col-md-8">
                    <span class="text-light font-size-13">Senha</span>
                    <p class="mb-0">**********</p>
                  </div>
                  <div class="col-md-4 text-md-right text-left">
                    <Link to="/perfil" class="text-primary">
                      Alterar
                    </Link>
                  </div>
                </div>
                <div class="row align-items-center justify-content-between mb-3">
                  <div class="col-md-8">
                    <span class="text-light font-size-13">
                      Data de Nascimento
                    </span>
                    <p class="mb-0">{moment(dtnasc).format("DD/MM/YYYY")}</p>
                  </div>
                  <div class="col-md-4 text-md-right text-left">
                    <Link to="/perfil" class="text-primary">
                      Alterar
                    </Link>
                  </div>
                </div>
                <div class="row align-items-center justify-content-between">
                  <div class="col-md-8">
                    <span class="text-light font-size-13">
                      Escola de Origem
                    </span>
                    <p class="mb-0">{escorigem}</p>
                  </div>
                  <div class="col-md-4 text-md-right text-left">
                    <Link to="/perfil" class="text-primary">
                      Alterar
                    </Link>
                  </div>
                </div>
                <h5 class="mb-3 mt-4 pb-3 a-border">Detalhes de Pagamento</h5>
                <div class="row justify-content-between mb-3">
                  <div class="col-md-8 r-mb-15">
                    <p>Sua próxima data de pagameto é 05 de Maio de 2021.</p>
                    <a href="#" class="btn btn-hover">
                      Cancelar Assinatura
                    </a>
                  </div>
                  <div class="col-md-4 text-md-right text-left">
                    <a href="#" class="text-primary">
                      Alterar Forma de Pagamento
                    </a>
                  </div>
                </div>
                <h5 class="mb-3 mt-4 pb-3 a-border">Meu Plano</h5>
                <div class="row justify-content-between mb-3">
                  <div class="col-md-8">
                    <p>Premium</p>
                  </div>
                  <div class="col-md-4 text-md-right text-left">
                    <a href="pricing-plan.html" class="text-primary">
                      Alterar Plano
                    </a>
                  </div>
                </div>
                {/* <h5 class="mb-3 pb-3 mt-4 a-border">Setting</h5>
                        <div class="row">
                            <div class="col-12 setting">
                                <a href="#" class="text-body d-block mb-1">Recent device streaming activity</a>
                                <a href="#" class="text-body d-block mb-1">Sign out of all devices </a>
                                <a href="#" class="text-body d-block">Download your person information</a>
                            </div>                            
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Rodape />
      <div id="back-to-top">
        <a class="top" href="#top" id="top">
          {" "}
          <i class="fa fa-angle-up"></i>{" "}
        </a>
      </div>
    </>
  );
};

export default Configuracoes;
