import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./termos.css";
import Topo from "../../components/TopoSite/Topo";
import Topologado from "../../components/Topo/Topo";
import Rodape from "../../components/Rodape/Rodape";

const Termos = () => {
  const [logado, setLogado] = useState(false);
  useEffect(() => {
    const id = localStorage.getItem("@educa-app/id");
    if (id == null) {
      setLogado(false);
    } else {
      setLogado(true);
    }
  }, []);

  return (
    <>
      { logado == true ? <Topologado /> : <Topo />}
      <div
        class="iq-breadcrumb-one  iq-bg-over iq-over-dark-50"
        id="topo-background-termos"
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <nav
                aria-label="breadcrumb"
                class="text-center iq-breadcrumb-two"
              >
                <h2 class="title">Termos de Uso</h2>
                <ol class="breadcrumb main-bg">
                  <li class="breadcrumb-item">
                    <a href="">Home</a>
                  </li>
                  <li class="breadcrumb-item active">Termos de Uso</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <main id="main" class="site-main">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-sm-12">
              <div class="iq-privacy-policy">
                <div class="mb-3">
                  <h4 class="mb-3">1. Introdução e definições</h4>
                  <p class="my-2 texto-justificado">
                    As condições e termos de uso (“Termos”) estabelecidos abaixo
                    regem o relacionamento entre o EDUCAFLIX - Plataforma
                    Educacional, neste representada pela Empresa APP EDUCA,
                    titular da propriedade intelectual sobre software, website,
                    aplicativos, conteúdos e demais ativos digitais ou
                    tangíveis, relacionados à plataforma do EDUCAFLIX
                    (“EDUCAFLIX”), em sua relação com os usuários de sua
                    plataforma para quaisquer finalidades (“Usuário”).
                  </p>
                  <p class="my-2 texto-justificado">
                    Os Termos, além de regularem contratualmente a relação entre
                    as partes, consistem em licença de uso ao Usuário dos
                    Serviços pelo EDUCAFLIX. Para a utilização de qualquer forma
                    dos Serviços ofertados pelo EDUCAFLIX, é obrigatório que o
                    Usuário aceite, de forma expressa e específica, os Termos e
                    a Política dePrivacidade do EDUCAFLIX.
                  </p>
                  <p class="my-2 texto-justificado">
                    A Política de Privacidade do EDUCAFLIX estabelece as
                    condições em que o EDUCAFLIX coleta, armazena, utiliza,
                    trata e transfere as informações e dados no âmbito dos
                    Serviços licenciados. Esse documento constitui também parte
                    integrante destes Termos e deverá ser lido e consentido por
                    todo e qualquer Usuário, como requisito para a utilização da
                    plataforma do EDUCAFLIX.
                  </p>
                  <p class="my-2 texto-justificado">
                    Caso você, Usuário, não concorde com quaisquer das condições
                    aqui descritas, orientamos a não usar os serviços ofertados
                    pelo EDUCAFLIX, uma vez que sua utilização será
                    automaticamente entendida como concordância irrevogável com
                    os Termos.
                  </p>
                  <p class="my-2 texto-justificado">
                    O Usuário deverá sempre ler atentamente os Termos de Uso e a
                    Política de Privacidade, e não poderá se escusar deles,
                    alegando ignorância sobre suas condições, inclusive quanto a
                    eventuais modificações.
                  </p>
                  <br />
                </div>
                <div class="mb-3">
                  <h4 class="mb-3">2. Objeto</h4>
                  <p class="my-2 texto-justificado">
                    O EDUCAFLIX é uma plataforma desenvolvida com o objetivo de
                    licenciar o uso de seu software, websites, plataforma,
                    aplicativo ou qualquer outro ativo ou conteúdo intelectual
                    voltado para o ensino à distância, o que inclui também
                    materiais para aprendizado e preparação em exames e
                    vestibulares, ou qualquer outro conteúdo que facilite o
                    encontro entre aqueles que demandam e aqueles que ofertam
                    serviços educacionais, incluindo videoaulas, exercícios,
                    materiais didáticos e correção de redações, o que será
                    doravante denominado de “Serviços”.
                  </p>
                  <p class="my-2 texto-justificado">
                    Todos os Serviços são oferecidos na forma como se encontram
                    (“as is”), podendo o EDUCAFLIX implementar livremente
                    mudanças, alterações, adições, supressões e quaisquer outras
                    formas de modificação nos Serviços, sem a necessidade de
                    notificação prévia, podendo inclusive deixar de oferecer os
                    Serviços ou parte deles a qualquer momento, desde que atenda
                    o serviço pago pelo(s) usuário(s) durante a vigência do
                    Plano Contratado.
                  </p>
                  <br />
                </div>
                <div class="mb-3">
                  <h4 class="mb-3">3. Acesso, cadastro e dados do usuário</h4>
                  <p class="my-2 texto-justificado">
                    O acesso aos Serviços, independentemente do meio utilizado
                    para acessá-los, exige a elaboração de um cadastro e o
                    pagamento de um valor do Plano escolhido para acesso do
                    serviço.
                  </p>
                  <p class="my-2 texto-justificado">
                    Os campos de cadastro deverão ser preenchidos com
                    informações completas, recentes, válidas e corretas, sendo
                    de exclusiva responsabilidade do Usuário manter todas as
                    informações fornecidas ao EDUCAFLIX, em razão do cadastro,
                    permanentemente atualizadas, de forma a sempre refletir os
                    dados reais do Usuário.
                  </p>
                  <p class="my-2 texto-justificado">
                    Ao se cadastrar no EDUCAFLIX, o Usuário garante de forma
                    expressa que é plenamente capaz, nos termos da legislação
                    vigente, para exercer e gozar de todos os Serviços. Ademais,
                    os Usuários menores de 18 anos deverão obter o consentimento
                    expresso de seus pais, tutores ou representantes legais para
                    utilizar os Serviços, conforme as disposições dos Termos e
                    da Política de Privacidade do EDUCAFLIX. Os pais, tutores ou
                    representantes legais serão plenamente responsáveis também
                    no caso de acesso ao EDUCAFLIX por parte de crianças e
                    adolescentes, sem a devida obtenção de autorização prévia.
                    Cabe a eles a integral responsabilidade pela fiscalização
                    das atividades e conduta dos respectivos menores sob sua
                    tutela, bem como ciência da integralidade dos presentes
                    Termos.
                  </p>
                  <p class="my-2 texto-justificado">
                    Os Serviços, bem como seu site, aplicativo ou quaisquer
                    outras plataformas, podem integrar livremente, no âmbito de
                    seus Serviços, aplicações de terceiros que se manifestam de
                    maneira ostensiva ou não nos Serviços. Esses serviços de
                    terceiros serão regidos única e exclusivamente pelos termos
                    de uso a eles aplicáveis, definidos por cada terceiro por
                    eles responsáveis. O Usuário autoriza que os Serviços criem
                    canais de comunicação diretos ou indiretos com ele, seja por
                    e-mail, redes sociais, notificações de celular, ligações
                    telefônicas e outras modalidades.
                  </p>
                  <p class="my-2 texto-justificado">
                    O Usuário, ao aceitar os Termos e Política de Privacidade do
                    EDUCAFLIX, autoriza expressamente o EDUCAFLIX a coletar,
                    usar, armazenar, tratar, ceder ou utilizar as informações
                    derivadas do uso dos Serviços, do site e quaisquer
                    plataformas do EDUCAFLIX, incluindo todas as informações
                    preenchidas pelo Usuário no momento em que realizar ou
                    atualizar seu cadastro, além de outras expressamente
                    descritas neste Termo e na Política de Privacidade, com a
                    finalidade de: (i) desenvolver novos produtos, conteúdo e
                    funcionalidades, bem como aperfeiçoar e divulgar os Serviços
                    prestados aos Usuários; (ii) analisar, gerar relatórios a
                    respeito da performance dos Usuários, de forma direta ou
                    indireta; (iii) agregar, analisar, tratar e anonimizar os
                    dados obtidos; (iv) preparar materiais para treinamentos
                    internos e externos; (v) disponibilizar dados para empresas
                    do mesmo grupo econômico ou para plataformas e ferramentas
                    contratadas pelo EDUCAFLIX, para execução e divulgação dos
                    serviços previstos nestes Termos; (vi) utilizar as
                    informações em anúncios publicitários com veiculação em
                    qualquer tipo de mídia; e (vii) outras atividades previstas
                    pela Política de Privacidade do EDUCAFLIX. O Usuário confere
                    por meio destes Termos autorização livre, específica,
                    expressa e informada a respeito da utilização e do
                    tratamento, pelo EDUCAFLIX, dos dados aqui descritos.
                  </p>
                  <p class="my-2 texto-justificado">
                    As informações fornecidas em seu cadastro serão armazenadas
                    em um banco de dados seguro e utilizadas de acordo com os
                    termos da Política de Privacidade. O seu cadastro no Site é
                    pessoal e protegido por uma senha criada por você. É de sua
                    responsabilidade criar uma senha segura e manter essa senha
                    confidencial para evitar qualquer acesso indevido às suas
                    informações pessoais. Você também será responsável por todas
                    as atividades realizadas com o uso da sua senha. Caso ocorra
                    o uso indevido de sua senha ou a quebra de segurança da
                    senha, você deverá informar o EDUCAFLIX sobre esse fato
                    imediatamente.
                  </p>
                  <p class="my-2 texto-justificado">
                    A senha é garantia ao Usuário de acesso individual às
                    informações disponibilizadas pelo EDUCAFLIX e protege sua
                    conta, sendo o Usuário o único responsável por mantê-la
                    confidencial e em segurança.
                  </p>
                  <p class="my-2 texto-justificado">
                    O Usuário é responsável por certificar-se que seus
                    equipamentos são condizentes com as características e
                    técnicas necessárias para o acesso e utilização dos
                    Serviços, bem como em relação a quaisquer programas de
                    computador necessários, incluindo de terceiros, e conexão à
                    internet em alta velocidade ou banda larga.
                  </p>
                  <br />
                </div>
                <div class="mb-3">
                  <h4 class="mb-3">
                    4. Condições de contratação e cancelamento
                  </h4>
                  <p class="my-2 texto-justificado">
                    O Usuário deverá pagar ao EDUCAFLIX o valor ofertado no
                    Website no momento da aquisição de um plano (“Plano”), nas
                    condições e formas indicadas no mesmo.
                  </p>
                  <p class="my-2 texto-justificado">
                    O acesso ao EDUCAFLIX só será liberado após a confirmação do
                    pagamento.
                  </p>
                  <p class="my-2 texto-justificado">
                    Quando da aquisição de um Plano (Plano, poderão ser
                    solicitadas informações financeiras, tais como CPF, dados do
                    seu cartão de crédito ou débito, ou de sua conta bancária,
                    conforme a opção escolhida pelo Usuário e endereço de
                    cobrança. O Usuário concorda que sejam cobrados, de acordo
                    com a forma de pagamento que venha a ser escolhida, os
                    preços então vigentes para o Plano adquirido, incluindo os
                    tributos e outras despesas aplicáveis.
                  </p>
                  <p class="my-2 texto-justificado">
                    As informações financeiras coletadas dos Usuários pelo
                    EDUCAFLIX poderão ser armazenadas para facilitar acessos e
                    contratações futuras, nos termos da Política de Privacidade.
                  </p>
                  <p class="my-2 texto-justificado">
                    Os Planos poderão ser renovados automaticamente pelo
                    EDUCAFLIX, independentemente de comunicação ao Usuário,
                    mediante cobrança periódica por meio da mesma forma de
                    pagamento indicada pelo Usuário quando da assinatura do
                    Plano.
                  </p>
                  <p class="my-2 texto-justificado">
                    O EDUCAFLIX poderá alterar os preços para assinatura ou
                    aquisição dos Planos a qualquer momento e independentemente
                    de qualquer comunicação aos Usuários.
                  </p>
                  <p class="my-2 texto-justificado">
                    O Usuário poderá cancelar a contratação dos Serviços de
                    acordo com os termos que forem definidos na oferta dos
                    mesmos no momento de sua contratação. O Usuário também
                    poderá cancelar os serviços em até 7 (sete) dias após a
                    contratação do Plano, de acordo com a Lei no. 8.078/90
                    (“Código de Defesa do Consumidor”), desde que o cancelamento
                    seja feito antes de o Usuário iniciar efetivamente o uso dos
                    Serviços, hipótese na qual não haverá devolução dos valores
                    pagos.
                  </p>
                  <p class="my-2 texto-justificado">
                    Na hipótese de o cancelamento ser efetuado pelo Usuário
                    dentro do prazo de 7 (sete) dias, os valores eventualmente
                    pagos pelo Usuário serão reembolsados em até 60 (sessenta)
                    dias contados da data em que o arrependimento foi informado
                    ao EDUCAFLIX, mediante estorno no cartão de crédito ou
                    depósito em conta bancária, conforme aplicável, desde que o
                    Usuário não tenha iniciado o uso dos Serviços.
                  </p>
                  <p class="my-2 texto-justificado">
                    Caso o EDUCAFLIX cancele seu acesso aos Serviços devido à
                    verificação de prática de atos que são vedados por estes
                    Termos de Uso, nenhum valor será reembolsado ou pago ao
                    Usuário em decorrência desse cancelamento.
                  </p>
                  <br />
                </div>
                <div class="mb-0">
                  <h4 class="mb-3">5. Propriedade Intelectual</h4>
                  <p class="my-2 texto-justificado">
                    Observados o disposto nas Leis nº 9.279/1996 (“Lei da
                    Propriedade Industrial”), 9.609/1998 (“Lei do Software”) e
                    9.610/1998 (“Lei de Direitos Autorais”), e sem prejuízo das
                    demais normas relativas à proteção da propriedade
                    intelectual, todos os direitos de propriedade intelectual do
                    EDUCAFLIX e de seus Serviços estão reservados, assim como
                    todas as marcas, logotipos, nomes comerciais, layouts,
                    gráficos e design de interface, imagens, ilustrações,
                    fotografias, apresentações, vídeos, conteúdos escritos e de
                    som e áudio, programas de computador, banco de dados,
                    arquivos de transmissão e quaisquer outras informações ou
                    obras protegidas pela legislação aplicável.
                  </p>
                  <p class="my-2 texto-justificado">
                    O EDUCAFLIX concede aos Usuários, por meio destes Termos e
                    durante sua vigência, uma licença não exclusiva, não
                    transferível e não sublicenciável, para acessar e fazer uso
                    do Serviço. Nem estes Termos de Uso nem o uso dos Serviços
                    transfere ou concede aos Usuários quaisquer direitos, exceto
                    pela licença limitada concedida acima. Todos os direitos não
                    expressamente concedidos aos Usuários são reservados ao
                    EDUCAFLIX, afiliadas e demais empresas do grupo econômico.
                  </p>
                  <p class="my-2 texto-justificado">
                    Dessa forma, todos os elementos constantes dos Serviços
                    deverão ser utilizados pelos Usuários exclusivamente para
                    uso pessoal, individual e intransferível, sendo vedado
                    qualquer uso não autorizado, comercial ou não-comercial, e
                    qualquer uso que não aconteça exclusivamente dentro do
                    ambiente da plataforma do EDUCAFLIX. Essa disposição se
                    aplica também a eventuais Planos “Familiar”, “Corporativo”
                    ou similares, caso em que a utilização de Serviços será
                    autorizada às pessoas indicadas na descrição no Plano ou
                    expressamente indicadas no cadastro feito junto ao
                    EDUCAFLIX, nos termos da oferta contratada. Tais usos
                    consistirão tanto em violação dos direitos de propriedade
                    intelectual do EDUCAFLIX, indenizáveis nos termos da Lei de
                    Direitos Autorais, bem como puníveis nos termos da
                    legislação penal aplicável.
                  </p>
                  <p class="my-2 texto-justificado">
                    Ao gerar qualquer conteúdo por meio e nos Serviços do
                    EDUCAFLIX, os Usuários concedem ao EDUCAFLIX uma licença
                    não-exclusiva e não-onerosa, sem limitação geográfica,
                    passível de ser sublicenciada e transferida, pelo prazo
                    total de vigência da proteção dos direitos autorais definido
                    pela legislação aplicável, para: usar, reproduzir, alterar,
                    remixar, realizar obras derivadas, adaptar, traduzir,
                    incorporar incluir em obra, distribuir, exibir, executar,
                    extrair trechos, exibir publicamente, ou de qualquer forma
                    utilizar o conteúdo em outras modalidades de uso existentes
                    ou que venham a ser inventadas.
                  </p>
                </div>
                <div class="mb-0">
                  <h4 class="mb-3">6. Práticas vedadas</h4>
                  <p class="my-2 texto-justificado">
                    São práticas expressamente vedadas ao Usuário, sem prejuízo
                    de outras que representem infração a estes Termos:{" "}
                  </p>
                  <p class="my-2 texto-justificado">
                    <ul>
                      <li>
                        1. a comercialização, reprodução total ou parcial,
                        publicação, retransmissão, distribuição, comunicação ao
                        público, transferência a terceiros ou qualquer
                        modificação, sob qualquer forma, por parte do Usuário de
                        qualquer material disponibilizado pelo EDUCAFLIX nos
                        Serviços, que não seja expressamente permitido por estes
                        Termos;
                      </li>
                      <li>
                        2. a comercialização ou o compartilhamento do login e
                        senha de acesso do Usuário aos Serviços do EDUCAFLIX;
                      </li>
                      <li>
                        3. contorno de qualquer tecnologia utilizada pelo
                        EDUCAFLIX, seus licenciadores ou terceiros para proteger
                        o conteúdo acessível através das plataformas, o que
                        inclui descompilar, reverter a engenharia, desmontar ou
                        de outro modo reduzir o código utilizado em qualquer
                        software a uma forma legível, com a finalidade de
                        examinar a construção desse software e/ou de copiar ou
                        criar outros produtos com base (no todo ou em parte)
                        nele, exceto quando for licenciado pelas licenças que
                        expressamente permitam essas atividades;
                      </li>
                      <li>
                        4. o aluguel, a locação ou a cessão onerosa de qualquer
                        parte dos Serviços;
                      </li>
                      <li>
                        5. a manipulação dos Serviços, empregando, sem
                        autorização prévia e expressa, mecanismos técnicos que
                        de qualquer forma subvertam a regular utilização do
                        EDUCAFLIX, como a utilização de robôs (robots, ou bots),
                        spiders, scripts ou qualquer outra forma de acesso
                        automatizado que de alguma forma sirva para desvirtuar
                        suas finalidades e propósitos; e
                      </li>
                      <li>
                        6. a remoção ou alteração de quaisquer direitos
                        autorais, marcas comerciais ou outros avisos de
                        propriedade intelectual contidos ou fornecidos pelos
                        Serviços.
                      </li>
                    </ul>
                  </p>
                </div>
                <div class="mb-0">
                  <h4 class="mb-3">7. Diretrizes do usuário</h4>
                  <p class="my-2 texto-justificado">
                    <ul>
                      <li>
                        1. deixar de empreender qualquer atividade que possa
                        comprometer a disponibilidade ou confiabilidade da
                        prestação dos Serviços;
                      </li>
                      <li>
                        2. fornecer as informações solicitadas para cadastro,
                        responsabilizando-se por sua veracidade e exatidão, sob
                        pena de comprometimento da prestação dos Serviços; e
                      </li>
                      <li>
                        3. usar de boa-fé os Serviços ofertados e em
                        conformidade com todos os preceitos legais,
                        regulamentares e contratuais aplicáveis, bem como com a
                        ordem pública, a moral e os bons costumes e as normas de
                        conduta e etiqueta geralmente aceitas.{" "}
                      </li>
                    </ul>
                  </p>
                  <p class="my-2 texto-justificado">
                    Ao Usuário é vedado, além das disposições acima e de
                    disposições legais:{" "}
                  </p>
                  <p class="my-2 texto-justificado">
                    <ul>
                      <li>
                        1. fornecer sua senha para qualquer outra pessoa ou usar
                        o nome de usuário e senha de qualquer outra pessoa;
                      </li>
                      <li>
                        2. fornecer conteúdo ou registrar como dados cadastrais
                        em que estejam inseridos: (i) material ofensivo,
                        abusivo, discriminatório, difamatório, pornográfico ou
                        obsceno; e (ii) material ilegal, ou destinado a promover
                        ou cometer um ato ilegal de qualquer tipo, incluindo,
                        sem limitação, violação dos direitos de propriedade
                        intelectual, direitos de privacidade ou direitos de
                        propriedade do EDUCAFLIX ou de terceiros;
                      </li>
                      <li>
                        3. incluir conteúdo malicioso, como malware, cavalos de
                        Troia ou vírus, ou que de outra forma interfira no
                        acesso de qualquer Usuário ao Serviço;
                      </li>
                      <li>
                        4. qualquer conteúdo gerado pelo Usuário que seja
                        passível de proteção intelectual que seja inserido nos
                        Serviços do EDUCAFLIX é automaticamente licenciado na
                        íntegra para uso nos Serviços do EDUCAFLIX, sem
                        exclusividade, com validade global, pelo prazo total de
                        vigência da proteção de direitos autorais; e
                      </li>
                      <li>
                        5. não extrair da plataforma qualquer dado pessoal ou
                        informação de modo geral, inclusive por meio de
                        processos como scraping, uso de robôs, crawlers, e
                        quaisquer outras técnicas, mesmo que manuais, para
                        quaisquer finalidades, sem a autorização prévia e
                        expressa do EDUCAFLIX.{" "}
                      </li>
                    </ul>
                  </p>
                  <p class="my-2 texto-justificado">
                    Caso o Usuário identifique qualquer atuação desconforme com
                    os presentes Termos por qualquer outro Usuário, poderá
                    notificar o EDUCAFLIX a esse respeito.
                  </p>
                  <br />
                </div>
                <div class="my-2">
                  <h4 class="mb-3">8. Limitação de Responsabilidades</h4>
                  <p class="my-2 texto-justificado">
                    O EDUCAFLIX poderá ser notificado a qualquer momento com
                    relação a conteúdos constantes de sua plataforma
                    considerados infringentes de direitos de terceiros e atuará
                    prontamente para promover a tempestiva remoção desses
                    conteúdos, nos termos da Lei nº 12.965, de 2014 (“Marco
                    Civil da Internet”), e da jurisprudência consolidada no
                    Brasil a respeito da responsabilidade de websites e
                    intermediários.
                  </p>
                  <p class="my-2 texto-justificado">
                    Qualquer notificação a respeito de conteúdo infringentes
                    deverá conter, sob pena de nulidade, identificação clara e
                    específica do conteúdo apontado como infringente, que
                    permita a localização inequívoca do material. Todas as
                    notificações devem ser feitas de boa-fé. A notificação não
                    pode ser realizada de forma anônima, devendo o notificante
                    se identificar de forma inequívoca, fornecendo identidade,
                    CPF ou CNPJ e explicitando sua relação jurídica com o
                    conteúdo em questão, nos termos da lei.
                  </p>
                  <p class="my-2 texto-justificado">
                    Os Usuários concordam que o EDUCAFLIX poderá transferir ou
                    ceder sua posição contratual nestes Termos de Uso ou
                    qualquer direito ou obrigação deles decorrentes a qualquer
                    tempo, sem a necessidade de prévio aviso ao ou consentimento
                    do Usuário ou Corretor Usuário, inclusive em razão de
                    operações societárias tais como, mas não se limitando, a
                    fusões, aquisições, reestruturações.{" "}
                  </p>
                </div>
                <div class="my-2">
                  <h4 class="mb-3">9. Sanções</h4>
                  <p class="my-2 texto-justificado">
                    O uso dos Serviços do EDUCAFLIX em desacordo com estes
                    Termos implicará a exclusão do cadastro do Usuário e a
                    proibição da utilização dos Serviços, sem qualquer direito a
                    reembolso do valor pago. Seus dados serão preservados para
                    uso das autoridades competentes, conforme legislação
                    vigente, caso o EDUCAFLIX seja notificado ou acionado por um
                    Usuário ou por terceiro pela violação de direitos de
                    terceiros decorrente do mau uso da plataforma.
                  </p>
                  <p class="my-2 texto-justificado">
                    O Usuário concorda em indenizar o EDUCAFLIX, suas filiais,
                    empresas controladas ou controladoras, diretores,
                    administradores, colaboradores, representantes e empregados
                    por qualquer perda, responsabilização, reclamação ou
                    demanda, por quaisquer prejuízos resultantes da sua
                    utilização dos Serviços.{" "}
                  </p>
                </div>
                <div class="my-2">
                  <h4 class="mb-3">
                    10. Outras Informações automaticamente coletadas e
                    armazenadas
                  </h4>
                  <p class="my-2 texto-justificado">
                    O EDUCAFLIX poderá coletar automaticamente informações e
                    dados, incluindo dados de navegação e cabeçalhos de pacotes
                    de dados, bem como utilizar cookies e outros dispositivos de
                    análise de dados usualmente utilizados por sites e serviços
                    de internet. Em todos os casos, essas atividades
                    subordinam-se aos presentes Termos e à Política de
                    Privacidade vigentes.{" "}
                  </p>
                </div>
                <div class="my-2">
                  <h4 class="mb-3">11. Vigência</h4>
                  <p class="my-2 texto-justificado">
                    A contratação dos Serviços pelo Usuário será vigente pelo
                    período contratado, com início após a confirmação da
                    inscrição, mediante confirmação de recebimento de pagamento
                    da primeira parcela ou do valor integral do uso do Serviço.
                    Ao término do período de vigência, o Usuário deixará de ter
                    acesso à Plataforma e ao Serviço.
                  </p>
                  <p class="my-2 texto-justificado">
                    O EDUCAFLIX disponibilizará o acesso aos Serviços ao Usuário
                    somente após a constatação do aceite destes Termos de Uso,
                    do cadastro e pagamento pelo Usuário.{" "}
                  </p>
                </div>
                <div class="my-2">
                  <h4 class="mb-3">12. Extensão dos Termos</h4>
                  <p class="my-2 texto-justificado">
                    A versão válida e eficaz dos presentes Termos é aquela
                    publicada atualmente pela plataforma. Esta versão governa
                    todas as relações passadas e presentes entre os Usuários,
                    respeitados direitos do consumidor, direitos adquiridos,
                    atos jurídicos perfeitos e coisas julgadas.
                  </p>
                  <p class="my-2 texto-justificado">
                    Os Termos vinculam legalmente o Usuário com relação aos
                    atuais ou novos Serviços oferecidos pelo EDUCAFLIX, sendo
                    excepcionados apenas em caso de avenças em contrário
                    constantes de instrumentos específicos.{" "}
                  </p>
                </div>
                <div class="my-2">
                  <h4 class="mb-3">13. Autonomia</h4>
                  <p class="my-2 texto-justificado">
                    Na eventualidade de qualquer disposição dos Termos ser
                    considerada inválida ou inexequível, por qualquer motivo,
                    esta não deve afetar ou tornar inválidas ou inexequíveis as
                    disposições restantes.{" "}
                  </p>
                </div>
                <div class="my-2">
                  <h4 class="mb-3">14. Modificações destes termos</h4>
                  <p class="my-2 texto-justificado">
                    Estes Termos de Uso foram publicados em 27/06/2020 e
                    consistem na versão válida e eficaz de seus termos e
                    condições. Esta versão governa todas as relações entre o
                    EDUCAFLIX e os Usuários, respeitados os direitos adquiridos,
                    atos jurídicos perfeitos e coisas julgadas.
                  </p>
                  <p class="my-2 texto-justificado">
                    As cláusulas destes Termos deverão sobreviver a qualquer
                    forma de terminação, ocorrida por qualquer motivo, de modo a
                    continuar a produzir efeitos sobre as partes enquanto houver
                    relações jurídicas subsequentes.
                  </p>
                  <p class="my-2 texto-justificado">
                    O EDUCAFLIX reserva-se o direito de atualizar e modificar
                    quaisquer de seus documentos jurídicos, incluindo estes
                    termos de uso, bem como de modificar seu modelo de negócios
                    livremente, de acordo com o artigo 3º, inciso VIII do Marco
                    Civil. Qualquer modificação será postada publicamente pelo
                    EDUCAFLIX em suas plataformas e, quando necessário, será
                    notificado o Usuário e obtido consentimento.
                  </p>
                  <p class="my-2 texto-justificado">
                    Quaisquer modificações entrarão em vigor a partir de sua
                    publicação nas plataformas do Serviço.
                  </p>
                  <p class="my-2 texto-justificado">
                    Qualquer novo recurso que aprimore o Serviço atual, como a
                    disponibilização de novas ferramentas e recursos, estará
                    sujeito a estes Termos.{" "}
                  </p>
                </div>
                <div class="my-2">
                  <h4 class="mb-3">15. Escolha de foro</h4>
                  <p class="my-2 texto-justificado">
                    Estes Termos estão sujeitos às leis brasileiras.
                  </p>
                  <p class="my-2 texto-justificado">
                    Fica eleito exclusivamente o Foro da comarca de Ananindeua
                    para dirimir a resolução de quaisquer conflitos entre as
                    partes na interpretação e execução destes Termos de Uso.{" "}
                  </p>
                </div>
                <div class="my-2">
                  <h4 class="mb-3">16. Dúvidas</h4>
                  <p class="my-2 texto-justificado">
                    O Usuário deverá contatar o EDUCAFLIX no caso de qualquer
                    dúvida na leitura deste documento, através do e-mail
                    contato@webeducaflix.com .
                  </p>
                  <p class="my-2 texto-justificado">
                    O Usuário reconhece que as peculiaridades de uso dos
                    serviços foram suficientemente descritas neste Termo e que o
                    EDUCAFLIX cumpriu devidamente o dever de informação. Após
                    ler todas as condições que regulam o uso dos Serviços do
                    EDUCAFLIX, o Usuário consente com este Termo e aceita todas
                    as suas disposições.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Rodape />

      <div id="back-to-top">
        <a class="top" href="#top" id="top">
          {" "}
          <i class="fa fa-angle-up"></i>{" "}
        </a>
      </div>
    </>
  );
};

export default Termos;
