import React from "react";
import { Link, useHistory } from "react-router-dom";
// import "./planos.css";
import Topo from "../../components/TopoSite/Topo";
import Rodape from "../../components/Rodape/Rodape";

const Planos = () => {
  return (
    <>
      <Topo />
      <section class="m-profile">
        <div class="container">
          <h4 class="main-title mb-4">Planos e Preços</h4>
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="sign-user_card">
                <div class="table-responsive pricing pt-2">
                  <table id="my-table" class="table">
                    <thead>
                      <tr>
                        <th class="text-center prc-wrap"></th>
                        <th class="text-center prc-wrap">
                          <div class="prc-box">
                            <div class="h3 pt-4 text-white">
                              R$ 19,90<small> / Por mês</small>
                            </div>
                            <span class="type">Basico</span>
                          </div>
                        </th>
                        <th class="text-center prc-wrap">
                          <div class="prc-box active">
                            <div class="h3 pt-4 text-white">
                              R$ 209,90<small> / Por ano</small>
                            </div>
                            <span class="type">Premium</span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="text-center" scope="row">
                          New Movies
                        </th>
                        <td class="text-center child-cell">
                          <i class="ri-check-line ri-2x"></i>
                        </td>
                        <td class="text-center child-cell active">
                          <i class="ri-check-line ri-2x"></i>
                        </td>
                      </tr>
                      <tr>
                        <th class="text-center" scope="row">
                          Streamit Special
                        </th>
                        <td class="text-center child-cell">
                          <i class="ri-close-line i_close"></i>
                        </td>
                        <td class="text-center child-cell active">
                          <i class="ri-check-line ri-2x"></i>
                        </td>
                      </tr>
                      <tr>
                        <th class="text-center" scope="row">
                          American Tv Shows
                        </th>
                        <td class="text-center child-cell">
                          <i class="ri-close-line i_close"></i>
                        </td>
                        <td class="text-center child-cell active">
                          <i class="ri-check-line ri-2x"></i>
                        </td>
                      </tr>
                      <tr>
                        <th class="text-center" scope="row">
                          Hollywood Movies
                        </th>
                        <td class="text-center child-cell">
                          <i class="ri-check-line ri-2x"></i>
                        </td>
                        <td class="text-center child-cell active">
                          <i class="ri-check-line ri-2x"></i>
                        </td>
                      </tr>
                      <tr>
                        <th class="text-center" scope="row">
                          Video Quality
                        </th>
                        <td class="text-center child-cell">SD (480p)</td>
                        <td class="text-center child-cell active">HD (720p)</td>
                      </tr>
                      <tr>
                        <th class="text-center" scope="row">
                          Ad Free Entertainment
                        </th>
                        <td class="text-center child-cell">
                          <i class="ri-close-line i_close"></i>
                        </td>
                        <td class="text-center child-cell active">
                          <i class="ri-close-line i_close"></i>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center"></td>
                        <td class="text-center">
                          <Link to="/cadastro" class="btn btn-hover mt-3">
                            Assinar
                          </Link>
                        </td>
                        <td class="text-center">
                          <Link to="/cadastro" class="btn btn-hover mt-3">
                            Assinar
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Rodape />
      <div id="back-to-top">
        <a class="top" href="#top" id="top">
          {" "}
          <i class="fa fa-angle-up"></i>{" "}
        </a>
      </div>
    </>
  );
};

export default Planos;
