import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import "./apostila.css";
import Topo from "../../components/Topo/Topo";
import Rodape from "../../components/Rodape/Rodape";
// import { Document, Page, pdfjs } from "react-pdf";
// import SinglePagePDFViewer from "../../components/pdf/single-page";
// import PDFViewer from 'pdf-viewer-reactjs'
import PDFViewer from "mgr-pdf-viewer-react";
import api from "../../services/api";

const Apostila = (props) => {
  const history = useHistory();
  const [urlPdf, setUrlPdf] = useState();
  const [numPages, setNumPages] = useState(1);
  const [zoom, setZoom] = useState(1.5);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function increaseScale() {
    setZoom(zoom+0.1);
  }

  function decreaseScale() {
    setZoom(zoom-0.1);
  }

  const CustomPages = (props) => {
    const {
      page,
      pages
    } = props;
    setNumPages(pages);
    return <h6 style={{display: 'inline-block', marginTop: 0, fontSize: '10px'}}>Página {page} de {pages}</h6>;
  };

  function voltar(){
    //alert('Voltando...')
    history.push(`/conteudos/${props.location.state.iddisciplina}`);
  }
  
  
  useEffect(() => {
    let urlElements = window.location.href.split("/");
    window.scrollTo(0, 0);
    const id = localStorage.getItem("@educa-app/id");
    const token = localStorage.getItem("@educa-app/token");
    if (id == null) {
      window.location.href = "/";
    } else {
    }
  }, []);

  return (
    <>
      <Topo />
      <div class="main-content">
        <section class="movie-detail container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-sm-12 overflow-hidden">
                  <br />
                  <br />
                  <div class="trending-info season-info g-border">
                    <h4 class="trending-text big-title text-uppercase mt-0">
                      {props.location.state.disciplina}
                    </h4>
                    <div class="d-flex align-items-center text-white text-detail episode-name mb-0">
                      <span>{props.location.state.episodio}</span>
                    </div>
                    <p class="trending-dec w-100 mb-0">
                      {props.location.state.titulo}
                    </p>
                    <ul class="list-inline p-0 mt-4 share-icons music-play-lists">
                      <li onClick={voltar}>
                        <span>
                          <i class="fa fa-arrow-left" aria-hidden="true"></i>
                        </span>
                      </li>
                      {/* <li>
                        <span>
                          <i class="ri-add-line"></i>
                        </span>
                      </li>
                      <li>
                        <span>
                          <i class="ri-heart-fill"></i>
                        </span>
                      </li> */}
                    </ul>
                    <div class="toolbar-apostila">
                      <div class="btn-toolbar" role="toolbar">
                        <div class="btn-group btn-group-justified" role="group">
                          <div class="btn-group" role="group">
                            <button
                              id="voltar"
                              class="btn toolbar-apostila-btn"
                              onClick={decreaseScale}
                            >
                              <i
                                class="fa fa-search-minus fa-lg"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                          <div class="btn-group" role="group">
                            <button
                              id="voltar"
                              class="btn toolbar-apostila-btn"
                              disabled={pageNumber <= 1}
                              onClick={previousPage}
                            >
                              <i
                                class="fa fa-arrow-left fa-lg"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                          <div class="btn-group" role="group">
                            <span className="toolbar-apostila-numpages">
                              Página {pageNumber || (numPages ? 1 : "--")} de{" "}
                              {numPages || "--"}
                            </span>
                          </div>
                          <div class="btn-group" role="group">
                            <button
                              id="avancar"
                              class="btn toolbar-apostila-btn"
                              disabled={pageNumber >= numPages}
                              onClick={nextPage}
                            >
                              <i
                                class="fa fa-arrow-right fa-lg"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                          <div class="btn-group" role="group">
                            <button
                              id="avancar"
                              class="btn toolbar-apostila-btn"
                              onClick={increaseScale}
                            >
                              <i
                                class="fa fa-search-plus fa-lg"
                                aria-hidden="true"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <PDFViewer
                      document={{
                        url: props.location.state.url,
                      }}
                      loader={
                        <h5
                          style={{
                            color: "#fff",
                            marginTop: "250px",
                            marginBottom: "250px",
                          }}
                        >
                          Estamos carregando sua apostila, aguarde...
                        </h5>
                      }
                      scale={zoom}
                      page={pageNumber}
                      // hideNavbar={true}
                      navigation={{
                        elements: {
                          pages: CustomPages
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <br />
      <br />
      <Rodape />
      <div id="back-to-top">
        <a class="top" href="#top" id="top">
          {" "}
          <i class="fa fa-angle-up"></i>{" "}
        </a>
      </div>
    </>
  );
};

export default Apostila;
