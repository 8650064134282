import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Load from "./components/Load/Load";
import Login from "./views/Login/Login";
import Recuperar from "./views/Recuperar/Recuperar";
import Registro from "./views/Registro/Registro";
import Planos from "./views/Planos/Planos";
import Perfil from "./views/Perfil/Perfil";
import Configuracoes from "./views/Configuracoes/Configuracoes";
import Aluno from "./views/Aluno/Aluno";
import Atendimento from "./views/Atendimento/Atendimento";
import Termos from "./views/Termos/Termos";
import Home from "./views/Home/Home";
import Faq from "./views/Faq/Faq";
import Aula from "./views/Aula/Aula";
import Apostila from "./views/Apostila/Apostila";
import Exercicios from "./views/Exercicios/Exercicios";

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(()=>{
      setLoading(false)
    },3000)
  }, [])

  return (
    <Router>
      <Switch>
        <Route exact path="/">
        {
          loading ?
          <Load />
          :
          <Home />
        }
        </Route>
        <Route path="/entrar" component={Login} />
        <Route path="/assinar" component={Registro} />
        <Route path="/recuperar" component={Recuperar} />
        <Route path="/planos" component={Planos} />
        <Route path="/perfil" component={Perfil} />
        <Route path="/conta" component={Configuracoes} />
        <Route path="/aluno" component={Aluno} />
        <Route path="/conteudos" component={Aula} />
        <Route path="/material" component={Apostila} />
        <Route path="/atendimento" component={Atendimento} />
        <Route path="/termos" component={Termos} />
        <Route path="/faq" component={Faq} />
        <Route path="/atividades" component={Exercicios} />
      </Switch>
    </Router>
  );
}

export default App;
