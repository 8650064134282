import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import "./aula.css";
import cardup02 from "../../images/upcoming/02.jpg";
import { Form, Input } from "reactstrap";
import Topo from "../../components/Topo/Topo";
import Rodape from "../../components/Rodape/Rodape";
import CardAula from "../../components/Cards/Aulas/CardAula";
import Apostilas from "../../components/Cards/Apostilas/Apostilas";
import Atividades from "../../components/Cards/Atividades/Atividades";
import ReactPlayer from "react-player";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import SinglePagePDFViewer from "../../components/pdf/single-page";
import api from "../../services/api";
import samplePDF from "../../data/teste.pdf";

const Aula = () => {
  const [dadoslista, setDadoslista] = useState([]);
  const [dadoslistavideos, setDadoslistaVideos] = useState([]);
  const [dadoslistaapostilas, setDadoslistaApostilas] = useState([]);
  const [dadoslistaexercicios, setDadoslistaExercicios] = useState([]);
  const [codigodisciplina, setCodigodisciplina] = useState("");
  const [codigoconteudo, setCodigoconteudo] = useState("");
  const [urlvideo, setUrlvideo] = useState("");
  const [nmdisciplina, setNmdisciplina] = useState("");
  const [nmepisodio, setNmepisodio] = useState("");
  const [descricao, setDescricao] = useState("");
  const [sectionid, setSectionid] = useState("");
  const [order, setOrder] = useState("");
  const [pdfurl, setPdfUrl] = useState("");

  const [exercvisivel, setExercvisivel] = useState("");
  const [pdfvisivel, setPdfvisivel] = useState("");

  const screenPdf = useFullScreenHandle();
  const screenExercicios = useFullScreenHandle();

  const reportChange = useCallback((state, handle) => {
    if (handle === screenPdf) {
      if (state === true){
        setPdfvisivel('')
      } else {
        setPdfvisivel('invisible-pdf')
      }
    }
    if (handle === screenExercicios) {
      console.log('Screen 2 went to', state, handle);
      if (state === true){
        setExercvisivel('')
      } else {
        setExercvisivel('invisible-exercicios')
      }
    }
  }, [screenPdf, screenExercicios]);

  function showVideo(id) {
    const token = localStorage.getItem("@educa-app/token");
    let urlElements = window.location.href.split("/");
    setCodigodisciplina(urlElements[4]);
    async function getVideosDetalhes() {
      console.log(
        `/detalhesconteudo?codisciplina=${urlElements[4]}&idconteudo=${id}&auth_token=${token}`
      );
      const dados = await api
        .get(
          `/detalhesconteudo?codisciplina=${urlElements[4]}&idconteudo=${id}&auth_token=${token}`
        )
        .then((res) => {
          const result = res.data;
          if (result.status_code == "400") {
            //console.log(result);
          } else {
            setCodigoconteudo(result.dados);
            setUrlvideo(result.dados[0].video_url);
            setNmdisciplina(result.dados[0].title);
            setNmepisodio(result.dados[0].episodio);
            setDescricao(result.dados[0].summary);
            setSectionid(result.dados[0].section_id);
            setOrder(result.dados[0].order);
            window.scrollTo(0, 0);

            async function getEpisodioDadosVideo() {
              const dados = await api
                .get(
                  `/listaconteudoepisodio?codisciplina=${urlElements[4]}&codsection=${result.dados[0].section_id}&tipo=video&auth_token=${token}`
                )
                .then((res) => {
                  const result = res.data;
                  if (result.status_code == "400") {
                    //console.log(result);
                  } else {
                    console.log(result);
                    setDadoslistaVideos(result.dados);
                  }
                })
                .catch((error) => {
                  console.log(
                    "Não foi possível carregar as informações do usuário"
                  );
                });
            }
            getEpisodioDadosVideo();

            async function getEpisodioDadosApostilas() {
              const dados = await api
                .get(
                  `/listaconteudoepisodio?codisciplina=${urlElements[4]}&codsection=${result.dados[0].section_id}&tipo=other&auth_token=${token}`
                )
                .then((res) => {
                  const result = res.data;
                  if (result.status_code == "400") {
                    //console.log(result);
                  } else {
                    setDadoslistaApostilas(result.dados);
                  }
                })
                .catch((error) => {
                  console.log(
                    "Não foi possível carregar as informações do usuário"
                  );
                });
            }
            getEpisodioDadosApostilas();

            async function getEpisodioExercicios() {
              const dados = await api
                .get(
                  `/listaconteudoepisodio?codisciplina=${urlElements[4]}&codsection=${result.dados[0].section_id}&tipo=quiz&auth_token=${token}`
                )
                .then((res) => {
                  const result = res.data;
                  if (result.status_code == "400") {
                  } else {
                    setDadoslistaExercicios(result.dados);
                  }
                })
                .catch((error) => {
                  console.log(
                    "Não foi possível carregar as informações do usuário"
                  );
                });
            }
            getEpisodioExercicios();
          }
        })
        .catch((error) => {
          console.log("Não foi possível carregar as informações do usuário");
        });
    }
    getVideosDetalhes();
  }

  useEffect(() => {
    let urlElements = window.location.href.split("/");
    setCodigodisciplina(urlElements[4]);
    window.scrollTo(0, 0);
    setPdfUrl('https://api.educatecnologia.co/uploads/lesson_files/b0be5f54efab0f2d7e5fec96efb73870.pdf');
    setPdfvisivel('invisible-pdf');
    setExercvisivel('invisible-exercicios');
    const id = localStorage.getItem("@educa-app/id");
    const token = localStorage.getItem("@educa-app/token");
    if (id == null) {
      window.location.href = "/";
    } else {
      async function getEpisodio() {
        const dados = await api
          .get(`/episodio?codisciplina=${urlElements[4]}&auth_token=${token}`)
          .then((res) => {
            const result = res.data;
            if (result.status_code == "400") {
              //console.log(result);
            } else {
              console.log(result);
              setCodigoconteudo(result.dados);
              setUrlvideo(result.dados[0].video_url);
              setNmdisciplina(result.dados[0].title);
              setNmepisodio(result.dados[0].episodio);
              setDescricao(result.dados[0].summary);
              setSectionid(result.dados[0].section_id);
              setOrder(result.dados[0].order);

              async function getEpisodioDadosVideo() {
                const dados = await api
                  .get(
                    `/listaconteudoepisodio?codisciplina=${urlElements[4]}&codsection=${result.dados[0].section_id}&tipo=video&auth_token=${token}`
                  )
                  .then((res) => {
                    const result = res.data;
                    if (result.status_code == "400") {
                      //console.log(result);
                    } else {
                      setDadoslistaVideos(result.dados);
                    }
                  })
                  .catch((error) => {
                    console.log(
                      "Não foi possível carregar as informações do usuário"
                    );
                  });
              }
              getEpisodioDadosVideo();

              async function getEpisodioDadosApostilas() {
                const dados = await api
                  .get(
                    `/listaconteudoepisodio?codisciplina=${urlElements[4]}&codsection=${result.dados[0].section_id}&tipo=other&auth_token=${token}`
                  )
                  .then((res) => {
                    const result = res.data;
                    if (result.status_code == "400") {
                      //console.log(result);
                    } else {
                      setDadoslistaApostilas(result.dados);
                    }
                  })
                  .catch((error) => {
                    console.log(
                      "Não foi possível carregar as informações do usuário"
                    );
                  });
              }
              getEpisodioDadosApostilas();
              
              async function getEpisodioExercicios() {
                console.log( `/listaconteudoepisodio?codisciplina=${urlElements[4]}&codsection=${result.dados[0].section_id}&tipo=quiz&auth_token=${token}`);
                const dados = await api
                  .get(
                    `/listaconteudoepisodio?codisciplina=${urlElements[4]}&codsection=${result.dados[0].section_id}&tipo=quiz&auth_token=${token}`
                  )
                  .then((res) => {
                    const result = res.data;
                    if (result.status_code == "400") {
                      //console.log(result);
                    } else {
                      setDadoslistaExercicios(result.dados);
                    }
                  })
                  .catch((error) => {
                    console.log(
                      "Não foi possível carregar as informações do usuário"
                    );
                  });
              }
              getEpisodioExercicios();
            }
          })
          .catch((error) => {
            console.log("Não foi possível carregar as informações do usuário");
          });
      }
      getEpisodio();

      async function getVideosCienciasHumanas() {
        const dados = await api
          .get(
            `/listaconteudodisciplina?auth_token=${token}&coddisciplina=${urlElements[4]}`
          )
          .then((res) => {
            const result = res.data;
            if (result.status_code == "400") {
              //console.log(result);
            } else {
              setDadoslista(result.dados);
              //console.log(result);
            }
          })
          .catch((error) => {
            console.log("Não foi possível carregar as informações do usuário");
          });
      }
      getVideosCienciasHumanas();
    }
  }, []);

  return (
    <>
      {/* <div class={pdfvisivel}>
        <FullScreen handle={screenPdf} onChange={reportChange}>
          <div className="full-screenable-node" style={{background: "#141414"}}>
            <button onClick={screenPdf.exit}>
              Exit
            </button>
            <div class="container">
              <div class="box">
                <SinglePagePDFViewer pdf={samplePDF} />
              </div>
            </div>
          </div>
        </FullScreen>
      </div>
      <div class={exercvisivel}>
        <FullScreen handle={screenExercicios} onChange={reportChange}>
          <div className="full-screenable-node" style={{background: "green"}}>
            Second
            <button onClick={screenPdf.enter}>
              Switch
            </button>
            <button onClick={screenExercicios.exit}>
              Exit
            </button>
          </div>
        </FullScreen>
      </div> */}


      <Topo />
      <ReactPlayer
        url={urlvideo}
        controls
        playbackRate={1}
        height="100vh"
        width="100%"
        playing
        // playIcon={<button>Play</button>}
        // light="https://i.stack.imgur.com/zw9Iz.png"
      />

      {/* <div class="video-container iq-main-slider">
      <video class="video d-block" controls loop>
         <source src="http://www.youtube.com/embed/tdfWk9eL7nA" type="video/mp4" />
      </video>
   </div> */}

      {/* <PDFViewer document={{
        file: "/home2/apped764/enem.webeducaflix.com/uploads/lesson_files/7b950fa7d86acfa3dc00ee6bd387db81.pdf"
      }} /> */}

        

      <div class="main-content">
        <section class="movie-detail container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="trending-info season-info g-border">
                <h4 class="trending-text big-title text-uppercase mt-0">
                  {nmdisciplina}
                </h4>
                <div class="d-flex align-items-center text-white text-detail episode-name mb-0">
                  <span>{nmepisodio}</span>
                  {/* <span class="trending-year">{nmepisodio}</span> */}
                </div>
                <p class="trending-dec w-100 mb-0">{descricao}</p>
                {/* <ul class="list-inline p-0 mt-4 share-icons music-play-lists">
                  <li>
                    <span>
                      <i class="ri-add-line"></i>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="ri-heart-fill"></i>
                    </span>
                  </li>
                  <li class="share">
                    <span>
                      <i class="ri-share-fill"></i>
                    </span>
                    <div class="share-box">
                      <div class="d-flex align-items-center">
                        <a href="#" class="share-ico">
                          <i class="ri-facebook-fill"></i>
                        </a>
                        <a href="#" class="share-ico">
                          <i class="ri-twitter-fill"></i>
                        </a>
                        <a href="#" class="share-ico">
                          <i class="ri-links-fill"></i>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul> */}
                <br/>
                <div class="row">
                  <div class="col-sm-12 overflow-hidden">
                    <div class="iq-main-header d-flex align-items-center justify-content-between">
                      <h4 class="main-title">Vídeos</h4>
                    </div>
                  </div>
                </div>
                <div class="row">
                  {dadoslistavideos.map((videos) => (
                    <div
                      class="col-1-5 col-md-6 iq-mb-30"
                      key={videos.id.toString()}
                    >
                      <div class="epi-box">
                        <div class="epi-img position-relative">
                          <img
                            // src={cardup02}
                            src={`https://api.educatecnologia.co/uploads/lesson_files/videos/capas/${videos.id.toString()}.png`}
                            class="img-fluid img-zoom"
                            alt=""
                          />
                          {/* <div class="episode-number">Episódio {videos.order}</div> */}
                          <div class="episode-play-info">
                            <div class="episode-play">
                              {/* <a href="show-details.html"> */}
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => showVideo(videos.id)}
                              >
                                <i class="ri-play-fill"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="epi-desc p-3">
                          <div class="d-flex align-items-center justify-content-between">
                            <span class="text-white">{videos.date_added}</span>
                            <span class="text-primary">{videos.duration}</span>
                          </div>
                          <a 
                            style={{ cursor: "pointer" }}
                            onClick={() => showVideo(videos.id)}
                          >
                            <h6 class="epi-name text-white mb-0">
                              {videos.conteudo}
                            </h6>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div class="row">
                  <div class="col-sm-12 overflow-hidden">
                    <div class="iq-main-header d-flex align-items-center justify-content-between">
                      <h4 class="main-title">Apostilas</h4>
                    </div>
                  </div>
                </div>
                <div class="row">
                    {dadoslistaapostilas.map((apostilas) => (
                      <Apostilas key={apostilas.id.toString()} iddisciplina={codigodisciplina} disciplina={nmdisciplina} episodio={apostilas.title} tilulo={apostilas.conteudo} link={`https://api.educatecnologia.co/uploads/lesson_files/${apostilas.attachment}`} onClick={screenPdf.enter}/>
                    ))}
                </div>
                {
                  dadoslistaexercicios.length > 0 ?  
                    <>
                      <div class="row">
                        <div class="col-sm-12 overflow-hidden">
                          <div class="iq-main-header d-flex align-items-center justify-content-between">
                            <h4 class="main-title">Atividades</h4>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                          {dadoslistaexercicios.map((itemexercicio) => (
                            <Link 
                              to={{
                                  pathname: '/atividades',
                                  state: { 
                                      disciplina: nmdisciplina,
                                      titulo: itemexercicio.conteudo,
                                      episodio: itemexercicio.title,
                                      id: itemexercicio.id
                                  }
                                }}
                          >
                            {/* {itemexercicio.conteudo} */}
                            <Atividades key={itemexercicio.id.toString()} iddisciplina={codigodisciplina} disciplina={nmdisciplina} episodio={itemexercicio.title} tilulo={itemexercicio.conteudo} link={`https://api.educatecnologia.co/uploads/lesson_files/${itemexercicio.attachment}`} onClick={screenPdf.enter}/>
                          </Link>
                          ))}
                      </div>
                    </>
                  : '' }
              </div>
            </div>
          </div>
        </section>
        <section id="iq-favorites">
          <div class="container-fluid">
            <div class="block-space">
              <div class="row">
                <div class="col-sm-12 overflow-hidden">
                  <div class="iq-main-header d-flex align-items-center justify-content-between">
                    <h4 class="main-title">Últimas aulas</h4>
                    <a href="show-single.html" class="text-primary">
                      Ver todos
                    </a>
                  </div>
                </div>
              </div>
              <div class="row">
                {dadoslista.map((item) => (
                  <div
                    class="col-1-5 col-md-6 iq-mb-30"
                    key={item.id.toString()}
                  >
                    <div class="epi-box">
                      <div class="epi-img position-relative">
                        <img 
                          // src={cardup02} 
                          src={`https://api.educatecnologia.co/uploads/lesson_files/videos/capas/${item.id.toString()}.png`}
                          class="img-fluid img-zoom" 
                          alt="" 
                        />
                        {/* <div class="episode-number">Episódio {item.order}</div> */}
                        <div class="episode-play-info">
                          <div class="episode-play">
                            {/* <a href="show-details.html"> */}
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => showVideo(item.id)}
                            >
                              <i class="ri-play-fill"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="epi-desc p-3">
                        <div class="d-flex align-items-center justify-content-between">
                          <span class="text-white">{item.date_added}</span>
                          <span class="text-primary">{item.duration}</span>
                        </div>
                        <a 
                          style={{ cursor: "pointer" }}
                          onClick={() => showVideo(item.id)}
                        >
                          <h6 class="epi-name text-white mb-0">
                            {item.CONTEUDO}
                          </h6>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>

      <Rodape />
      <div id="back-to-top">
        <a class="top" href="#top" id="top">
          {" "}
          <i class="fa fa-angle-up"></i>{" "}
        </a>
      </div>
    </>
  );
};

export default Aula;
