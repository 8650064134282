import React, { useContext } from "react";
import AccordionContext from "./AccordionContext";
import "./AccordionStyles.css";

function AccordionHeader({ children, ...restProps }) {
  const [AccordionShow, setAccordionShow] = useContext(AccordionContext);

  function handleClick() {
    setAccordionShow(!AccordionShow);
  }

  return (
    <div className="accordion-header" onClick={handleClick} {...restProps}>
      {children}
      {AccordionShow ? (
        <div className="accordion-image">
            <i id="ico-head" aria-hidden="true" class="fa fa-minus active"></i>
        </div>
      ) : (
        <div class="accordion-image">
          <i aria-hidden="true" class="fa fa-plus inactive"></i>
        </div>
      )}
    </div>
  );
}

export default AccordionHeader;
