import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./atendimento.css";
import Topo from "../../components/TopoSite/Topo";
import Topologado from "../../components/Topo/Topo";
import Rodape from "../../components/Rodape/Rodape";

const Atendimento = () => {
  const [logado, setLogado] = useState(false);
  useEffect(() => {
    const id = localStorage.getItem("@educa-app/id");
    if (id == null) {
      setLogado(false);
    } else {
      setLogado(true);
    }
  }, []);
  return (
    <>
      { logado == true ? <Topologado /> : <Topo />}
      <div
        class="iq-breadcrumb-one  iq-bg-over iq-over-dark-50"
        id="topo-background"
        // style="background-image: url(images/about-us/01.jpg);"
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <nav
                aria-label="breadcrumb"
                class="text-center iq-breadcrumb-two"
              >
                <h2 class="title">Central de Atendimento</h2>
                <ol class="breadcrumb main-bg">
                  <li class="breadcrumb-item">
                    <a href="">Home</a>
                  </li>
                  <li class="breadcrumb-item active">Atendimento</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <main id="main" class="site-main">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-sm-12">
              <div class="row contact-detail text-center">
                <div class="col-md-4">
                  <div class="icon-box">
                    <span class="icon-svg icon-svg d-flex justify-content-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Capa_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 512 512"
                      >
                        {" "}
                        <g>
                          {" "}
                          <g>
                            {" "}
                            <path d="M256,0C156.748,0,76,80.748,76,180c0,33.534,9.289,66.26,26.869,94.652l142.885,230.257 c2.737,4.411,7.559,7.091,12.745,7.091c0.04,0,0.079,0,0.119,0c5.231-0.041,10.063-2.804,12.75-7.292L410.611,272.22 C427.221,244.428,436,212.539,436,180C436,80.748,355.252,0,256,0z M384.866,256.818L258.272,468.186l-129.905-209.34 C113.734,235.214,105.8,207.95,105.8,180c0-82.71,67.49-150.2,150.2-150.2S406.1,97.29,406.1,180 C406.1,207.121,398.689,233.688,384.866,256.818z"></path>{" "}
                          </g>{" "}
                        </g>{" "}
                        <g>
                          {" "}
                          <g>
                            {" "}
                            <path d="M256,90c-49.626,0-90,40.374-90,90c0,49.309,39.717,90,90,90c50.903,0,90-41.233,90-90C346,130.374,305.626,90,256,90z M256,240.2c-33.257,0-60.2-27.033-60.2-60.2c0-33.084,27.116-60.2,60.2-60.2s60.1,27.116,60.1,60.2 C316.1,212.683,289.784,240.2,256,240.2z"></path>{" "}
                          </g>{" "}
                        </g>{" "}
                        <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{" "}
                        <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{" "}
                        <g> </g> <g> </g> <g> </g>{" "}
                      </svg>
                    </span>
                  </div>

                  <div class="icon-box-content">
                    <h3 class="icon-box-title">
                      <span id="text-canais">Endereço</span>
                    </h3>
                    <p class="icon-box-description">
                    Rua Governador José Malcher, 153, sala 12 - Belém - PA
                    </p>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="iq-contact-list" data-element_type="column">
                    <div class="icon-box">
                      <span class="icon-svg icon-svg d-flex justify-content-center">
                        <svg
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="m439.277344 72.722656c-46.898438-46.898437-109.238282-72.722656-175.566406-72.722656-.003907 0-.019532 0-.023438 0-32.804688.00390625-64.773438 6.355469-95.011719 18.882812-30.242187 12.527344-57.335937 30.640626-80.535156 53.839844-46.894531 46.894532-72.71875 109.246094-72.71875 175.566406 0 39.550782 9.542969 78.855469 27.625 113.875l-41.734375 119.226563c-2.941406 8.410156-.859375 17.550781 5.445312 23.851563 4.410157 4.414062 10.214844 6.757812 16.183594 6.757812 2.558594 0 5.144532-.429688 7.667969-1.3125l119.226563-41.730469c35.019531 18.082031 74.324218 27.625 113.875 27.625 66.320312 0 128.667968-25.828125 175.566406-72.722656 46.894531-46.894531 72.722656-109.246094 72.722656-175.566406 0-66.324219-25.824219-128.675781-72.722656-175.570313zm-21.234375 329.902344c-41.222657 41.226562-96.035157 63.925781-154.332031 63.925781-35.664063 0-71.09375-8.820312-102.460938-25.515625-5.6875-3.023437-12.410156-3.542968-18.445312-1.429687l-108.320313 37.910156 37.914063-108.320313c2.113281-6.042968 1.589843-12.765624-1.433594-18.449218-16.691406-31.359375-25.515625-66.789063-25.515625-102.457032 0-58.296874 22.703125-113.109374 63.925781-154.332031 41.21875-41.21875 96.023438-63.921875 154.316406-63.929687h.019532c58.300781 0 113.109374 22.703125 154.332031 63.929687 41.226562 41.222657 63.929687 96.03125 63.929687 154.332031 0 58.300782-22.703125 113.113282-63.929687 154.335938zm0 0" />
                          <path d="m355.984375 270.46875c-11.421875-11.421875-30.007813-11.421875-41.429687 0l-12.492188 12.492188c-31.019531-16.902344-56.121094-42.003907-73.027344-73.023438l12.492188-12.492188c11.425781-11.421874 11.425781-30.007812 0-41.429687l-33.664063-33.664063c-11.421875-11.421874-30.007812-11.421874-41.429687 0l-26.929688 26.929688c-15.425781 15.425781-16.195312 41.945312-2.167968 74.675781 12.179687 28.417969 34.46875 59.652344 62.761718 87.945313 28.292969 28.292968 59.527344 50.582031 87.945313 62.761718 15.550781 6.664063 29.695312 9.988282 41.917969 9.988282 13.503906 0 24.660156-4.058594 32.757812-12.15625l26.929688-26.933594v.003906c5.535156-5.535156 8.582031-12.890625 8.582031-20.714844 0-7.828124-3.046875-15.183593-8.582031-20.714843zm-14.5 80.792969c-4.402344 4.402343-17.941406 5.945312-41.609375-4.195313-24.992188-10.710937-52.886719-30.742187-78.542969-56.398437s-45.683593-53.546875-56.394531-78.539063c-10.144531-23.667968-8.601562-37.210937-4.199219-41.613281l26.414063-26.414063 32.625 32.628907-15.636719 15.640625c-7.070313 7.070312-8.777344 17.792968-4.242187 26.683594 20.558593 40.3125 52.734374 72.488281 93.046874 93.046874 8.894532 4.535157 19.617188 2.832032 26.6875-4.242187l15.636719-15.636719 32.628907 32.628906zm0 0" />
                        </svg>
                      </span>
                    </div>
                    <div class="icon-box-content">
                      <h3 class="icon-box-title">
                        <span id="text-canais">Suporte / Vendas</span>
                      </h3>
                      <p class="icon-box-description">
                        <a target="_blank" href="https://wa.me/message/SDGXZQ4SAIVXG1">
                          (091) 99156-3718 / (91)99304-0260
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="iq-contact-list" data-element_type="column">
                    <div class="icon-box">
                      <span class="icon-svg icon-svg d-flex justify-content-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Capa_1"
                          x="0px"
                          y="0px"
                          viewBox="0 0 512 512"
                        >
                          {" "}
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path d="M467,80.609H45c-24.813,0-45,20.187-45,45v260.782c0,24.813,20.187,45,45,45h422c24.813,0,45-20.187,45-45V125.609 C512,100.796,491.813,80.609,467,80.609z M461.127,110.609l-6.006,5.001L273.854,266.551c-10.346,8.614-25.364,8.614-35.708,0 L56.879,115.61l-6.006-5.001H461.127z M30,132.267L177.692,255.25L30,353.543V132.267z M467,401.391H45 c-7.248,0-13.31-5.168-14.699-12.011l171.445-114.101l17.204,14.326c10.734,8.938,23.893,13.407,37.051,13.407 c13.158,0,26.316-4.469,37.051-13.407l17.204-14.326l171.444,114.1C480.31,396.224,474.248,401.391,467,401.391z M482,353.543 l-147.692-98.292L482,132.267V353.543z"></path>{" "}
                            </g>{" "}
                          </g>{" "}
                          <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{" "}
                          <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{" "}
                          <g> </g> <g> </g> <g> </g>{" "}
                        </svg>
                      </span>
                    </div>

                    <div class="icon-box-content">
                      <h3 class="icon-box-title">
                        <span id="text-canais">Email</span>
                      </h3>
                      <p class="icon-box-description">
                        <a
                          href="mailto:contato@webeducaflix.com"
                          class="__cf_email__"
                          data-cfemail="5f2c2a2f2f302d2b1f2c2b2d3a3e32362b713c3032"
                        >
                          contato@educatecnologia.co
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center iq-title-box iq-box iq-title-default">
                <h2 class="iq-title">Nossos Canais</h2>
                <p class="iq-title-desc">
                  Caso precise falar conosco, entre em contato por um de nossos
                  canais de atendiemnto que teremos prazer em ajuda-lo.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Rodape />
      <div id="back-to-top">
        <a class="top" href="#top" id="top">
          {" "}
          <i class="fa fa-angle-up"></i>{" "}
        </a>
      </div>
    </>
  );
};

export default Atendimento;
