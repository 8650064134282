import React from "react";
import "./rodape.css";
import playStore from "../../images/footer/01.png";
import appStore from "../../images/footer/02.jpg";
import { Link, useHistory } from "react-router-dom";

const Rodape = () => {
  return (
    <footer id="contact" class="footer-one iq-bg-dark">
      <div class="footer-top">
        <div class="container-fluid">
          <div class="row footer-standard">
            <div class="col-lg-7">
              <div class="widget text-left">
                <div class="menu-footer-link-1-container">
                  <ul id="menu-footer-link-1" class="menu p-0">
                    <li
                      id="menu-item-7314"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314"
                    >
                      <Link to="/termos">Termos de Uso</Link>
                    </li>
                    <li
                      id="menu-item-7316"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7316"
                    >
                      <Link to="/privacidade">Politica de Privacidade</Link>
                    </li>
                    <li
                      id="menu-item-7118"
                      class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118"
                    >
                      <Link to="/faq">FAQ</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="widget text-left">
                <div class="textwidget">
                  <p>
                    <small>
                      © 2021 EDUCAFLIX. Todos os direitos reservados. Todos os vídeos e programas nesta plataforma são marcas registradas da EducaFlix Inc. e todas as imagens e conteúdos relacionados são propriedade da EducaFlix Inc. A duplicação e cópia são estritamente proibidas. Todos os direitos reservados.
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 mt-4 mt-lg-0">
              <h6 class="footer-link-title">Siga-nos :</h6>
              <ul class="info-share">
                <li>
                  <a target="_blank" href="https://www.facebook.com/EducaflixOficial">
                    <i class="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://twitter.com/webeducaflix">
                    <i class="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.instagram.com/webeducaflix/">
                  <i class="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-lg-3 col-md-6 mt-4 mt-lg-0">
              <div class="widget text-left">
                {/* <div class="textwidget">
                  <h6 class="footer-link-title">EducaFlix App</h6>
                  <div class="d-flex align-items-center">
                    <a class="app-image" target="_blank" href="https://play.google.com/store/apps/details?id=com.appeduca.educaflix">
                      <img src={playStore} alt="play-store" />
                    </a>
                    <br />
                    <a class="ml-3 app-image" href="#">
                      <img src={appStore} alt="app-store" />
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Rodape;
