import React from 'react';
import PulseLoader from "react-spinners/PulseLoader";
import './load.css';
import educafilxlogo from "../../images/login/logo-preload.png";

const Load = () => {
    return (  
        <div className="page-load">
            <img src={educafilxlogo} alt="logo" />
            <div className="load-page-spinner">
                <PulseLoader color={'#fff'} loading={true} size={5} />
            </div>
        </div>
    )
}

export default Load;