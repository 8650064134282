import React from 'react';
import Cards from 'react-credit-cards';
import { FormGroup, Form, Input, Alert, Label } from "reactstrap";
import { urlbase, api_key_pagarme } from "../../services/Params";
import PulseLoader from "react-spinners/PulseLoader";
import moment from 'moment';
import 'react-credit-cards/es/styles-compiled.css';

export default class CreditCard extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
    visible: false,
    isloading: false,
    retornomsg: '' 
  };

  onDismiss = () => {
    this.setState({ visible: false });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const cartao = {
      "amount": this.props.valor,
      "card_number": this.state.number.replace(/\s/g, ''),
      "card_cvv": this.state.cvc.replace(/\s/g, ''),
      "card_expiration_date": this.state.expiry.replace(/\s/g, ''),
      "card_holder_name": this.state.name,
      "customer": {
        "external_id": localStorage.getItem("@educa-app/user_id").toString(),
        "name": this.props.nome,
        "email": this.props.email,
        "type": "individual",
        "country": "br",
        "documents": [
          {
            "type": "cpf",
            "number": "67415765095"
          }
        ],
        "phone_numbers": [
          "+551199999999"
        ]
      },
      "billing": {
        "address": {
          "street": "AAAAAA",
          "complementary": "TESTE",
          "street_number": "666",
          "neighborhood": "Aricanduva",
          "city": "SP",
          "state": "São Paulo",
          "zipcode": "66825010",
          "country": "br"
        },
        "name": "EMPRESA TESTE"
      },
      "payment_method": "credit_card",
      "items": [
        {
          "id": "1",
          "title": this.props.plano,
          "unit_price": this.props.valor,
          "quantity": 1,
          "tangible": true
        }
      ]
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(cartao),
    };
    this.setState({ isloading: true });
    fetch(`https://api.pagar.me/1/transactions?api_key=${api_key_pagarme}`, requestOptions)
    .then(response => response.json())
    .then(
      dados => { 
        if (dados.status == 'paid') {
          // Crio o lançamento
          var mescompetencia = new Date();
          var time = new Date();
          if (this.props.planoid === "1"){
            var validadeData = new Date();
            validadeData.setDate(time.getDate() + 7); // Adiciona 7 dias
          } else {
            var validadeData = new Date('2021-11-30');
          }
         
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Cookie", "ci_session=eef8751e4fac055db8e14b2f0c78c625e0a3080b");

          var raw = JSON.stringify({
            "dtvencimento": moment(validadeData).format("YYYY-MM-DD"),
            "competencia": moment(mescompetencia).format("MM/YYYY"),
            "valor": this.props.valorlan,
            "numparcela": 1,
            "idformapgto": 3,
            "users_id": localStorage.getItem("@educa-app/user_id"),
            "planos_id": this.props.planoid,
            "idtransacao": dados.id,
            "statustransacao": dados.status,
            "link": ""
          });

          console.log(raw);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          fetch(`${urlbase}/criarlancamento`, requestOptions)
          .then(response => response.json())
          .then(
            result => {
              // Salvo o cartão para pagamento futuro
              var myHeaderscard = new Headers();
              myHeaderscard.append("Content-Type", "application/json");
              myHeaderscard.append("Cookie", "ci_session=eef8751e4fac055db8e14b2f0c78c625e0a3080b");

              var rawcard = JSON.stringify({
                "userid":localStorage.getItem("@educa-app/user_id"),
                "id":dados.card.id,
                "brand":dados.card.brand,
                "holder_name":dados.card.holder_name,
                "first_digits":dados.card.first_digits,
                "last_digits":dados.card.last_digits,
                "country":dados.card.country,
                "fingerprint":dados.card.fingerprint,
                "expiration_date":dados.card.expiration_date
              });

              var requestOptionsCard = {
                method: 'POST',
                headers: myHeaderscard,
                body: rawcard,
                redirect: 'follow'
              };

              fetch(`${urlbase}/addcartao`, requestOptionsCard)
              .then(response => response.json())
              .then(
                result => {
                  this.setState({ isloading: false });
                  window.location.href ='/perfil';
                }
              )
              .catch(error => {
                this.setState({ isloading: false });
                console.log('error', error)
              });
            }
          )
          .catch(error => console.log('error', error));
        } else {
          this.setState({ isloading: false });
          this.setState({ visible: true })
          this.setState({ retornomsg: 'Pagamento não autorizado, favor informe outro cartão de crédito.' });
        }
      }
    )
  }

  handleInputFocus = (e) => {
    this.setState({ focus: e.target.name });
  }
  
  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  
  render() {
    return (
      <div id="PaymentForm">
        <Cards
          cvc={this.state.cvc}
          expiry={this.state.expiry}
          focused={this.state.focus}
          name={this.state.name}
          number={this.state.number}
        />
        <Alert color="danger" isOpen={this.state.visible} toggle={this.onDismiss} style={{ marginTop: "15px" }} >
          {this.state.retornomsg}
        </Alert>
        <div class="col-md-12">
        <form onSubmit={this.handleSubmit}>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Número do cartão</label>
                <Input
                  type="tel"
                  name="number"
                  placeholder="Número do cartão"
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <Label>Nome no cartão</Label>
                <Input
                  type="text"
                  name="name"
                  placeholder="Nome no cartão"
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <Label>Validade</Label>
                <Input
                  type="tel"
                  name="expiry"
                  placeholder="MMAA"
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <Label>CVC</Label>
                <Input
                  type="tel"
                  name="cvc"
                  placeholder="CVC"
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
            </div>
            {/* <input type="submit" value="Realizar pagamento" /> */}
            <button type="submit" className="btn btn-hover my-2" style={{ width: "100%" }} >
              {this.state.isloading == false ?
                  "Realizar pagamento"
                : 
                  <PulseLoader color={"#fff"} loading={true} size={5} />
              }
            </button>
          </div>
        </form>
        </div>
      </div>
    );
  }
}