import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./home.css";
import Topo from "../../components/TopoSite/Topo";
import Rodape from "../../components/Rodape/Rodape";
import SeuJeito from "./img/assista-do-seu-jeito.png";
import Matematica from "./img/aplicativo/matematica.jpg";
import Humanas from "./img/aplicativo/chumanas.jpg";
import Natureza from "./img/aplicativo/cnatureza.jpg";
import Linguagem from "./img/aplicativo/linguagem.jpg";
import Redacao from "./img/aplicativo/redacao.jpg";
import Simulados from "./img/aplicativo/simuladosenem.jpg";
import TV from "./img/device-group-tv.png";
import Computador from "./img/device-group-computer.png";
import Celulares from "./img/device-group-mobile-tablet.png";

const Home = () => {
  useEffect(() => {
    const id = localStorage.getItem("@educa-app/id");
    console.log('ID: '+id);
    if (id !== null) {
      window.location.href = "/aluno";
    }
  }, []);

  return (
    <>
      <Topo />
      <div class="main-home">
        <section class="wrapper-1">
          <div class="hero-content">
            <h1>
              Os melhores conteúdos <span>em um só lugar.</span>
            </h1>
            <br />
            <div class="planos">
              <div class="plano-1">
                <h3>
                  R$ 19,90<span>/mês*</span>
                </h3>
                <p class="p-plano-1">Inclui teste grátis de 7 dias.</p>
                <div class="btn-teste-gratis-1">
                  <Link style={{ padding: "18px 73px" }}
                    to={{
                      pathname: "/assinar",
                      state: {
                        plano: "1",
                      },
                    }}
                  >
                    ASSINE AGORA
                  </Link>
                </div>
              </div>
              <div class="plano-2">
                <h3>
                  De R$ 139,30 por R$ 99,90<span>/ano*</span>
                </h3>
                <p class="p-plano-2">Inclui teste grátis de 7 dias.</p>
                <div class="btn-teste-gratis-2">
                  <Link style={{ padding: "18px 73px" }}
                    to={{
                      pathname: "/assinar",
                      state: { plano: "2" },
                    }}
                  >
                    ASSINE AGORA
                  </Link>
                </div>
              </div>
            </div>
            <p class="p-oferta">
              *Oferta válida para o Brasil de 14/05/2021 a 31/07/2021. O preço
              poderá varias de acordo com a forma do pagamento.
            </p>
          </div>
        </section>
        <section class="wrapper-1">
          <div class="foreground-image">
            <img class="img-foreground" src={SeuJeito} alt="" />
          </div>
          <div class="meta">
            <h2>Estude do seu jeito</h2>
            <p>
              Aproveite a tela grande da Smart TV ou Estude no Tablet, Laptop ou
              Smartphone. Nosso conteudos não para de crescer. Além disso, para
              o melhor desempenho de todos, é possível estudar em até 4 telas ao
              mesmo tempo.
            </p>
          </div>
        </section>
        <section class="wrapper-1">
          <div class="meta-2">
            <h2>Originais Educaflix exclusivos</h2>
            <p>
              Só no Educaflix você tem acesso a conteúdos e questões inéditas.
            </p>
          </div>
          <div class="grid-capa">
            <img src={Matematica} alt="" />
          </div>
          <div class="grid-capa">
            <img src={Humanas} alt="" />
          </div>
          <div class="grid-capa">
            <img src={Natureza} alt="" />
          </div>
          <div class="grid-capa">
            <img src={Linguagem} alt="" />
          </div>
          <div class="grid-capa">
            <img src={Redacao} alt="" />
          </div>
          <div class="grid-capa">
            <img src={Simulados} alt="" />
          </div>
        </section>
        <section class="wrapper">
          <div class="meta-2">
            <h2>Disponível nos seus dispositivos favoritos</h2>
          </div>
          <div class="grid-device">
            <img src={TV} alt="" />
            <h3>TV</h3>
            <p>Android TV</p>
            <p>Chromecast</p>
            <p>TVs LG</p>
            <p>Samsung</p>
          </div>
          <div class="grid-device">
            <img src={Computador} alt="" />
            <h3>Computador</h3>
            <p>Chrome OS</p>
            <p>MacOS</p>
            <p>PC Windows</p>
          </div>
          <div class="grid-device">
            <img src={Celulares} alt="" />
            <h3>Celulares e tablets</h3>
            <p>Tablets Amazon Fire</p>
            <p>Celulares e tablets Android</p>
            <p>iPhone e iPad</p>
          </div>
        </section>
      </div>
      <Rodape />
      <div id="back-to-top">
        <a class="top" href="#top" id="top">
          {" "}
          <i class="fa fa-angle-up"></i>{" "}
        </a>
      </div>
    </>
  );
};

export default Home;
