import React from "react";
import { Link, useHistory } from "react-router-dom";
import "./recuperar.css";
import educafilxlogo from "../../images/logo-wh.png";
import { Form, Input } from "reactstrap";

const Recuperar = () => {
  return (
    <>
      <section class="sign-in-page">
        <div id="menu">
          <div id="left">
            <Link to="/">
              <img src={educafilxlogo} alt="logo" />
            </Link>
          </div>
          <div id="right">
            {/* <Link to="" className="registro-link-menu">Sair</Link> */}
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-center align-items-center height-self-center">
            <div class="col-lg-5 col-md-12 align-self-center">
              <div class="sign-user_card ">
                <div class="sign-in-page-data">
                  <div class="sign-in-from w-100 m-auto">
                    <h3 class="mb-3 text-center">Recuperação de Senha</h3>
                    <p class="text-body">
                      Enviaremos um email com instruções de como redefinir sua
                      senha.
                    </p>
                    <Form class="mt-4" action="">
                      <div class="form-group">
                        <Input
                          type="email"
                          class="form-control mb-0"
                          id="exampleInputEmail2"
                          placeholder="Entre com seu e-mail"
                          autocomplete="off"
                          required
                        />
                      </div>
                      <div class="sign-info">
                        <button type="submit" class="btn btn-hover my-2"  style={{ width: "100%" }} >
                          Enviar por email
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Recuperar;
