import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import "./exercicio.css";
import Topo from "../../components/Topo/Topo";
import Rodape from "../../components/Rodape/Rodape";
import api from "../../services/api";

const Exercicios = (props) => {
  const divStyle = {
    margin: "0 auto",
    maxWidth: "100%",
    width: "640px"
  };

const iframeStyle = {
    margin: "0 auto",
    maxWidth: "100%",
    width: "100%",
    height: "800px",
    border: "1px solid #cfcfcf"
  };

  const riddleID = props.location.state.id;
  const riddleUrl = "https://api.educatecnologia.co/home/quiz_mobile_web_view/"+riddleID;
      
  useEffect(() => {
    let urlElements = window.location.href.split("/");
    window.scrollTo(0, 0);
    const id = localStorage.getItem("@educa-app/id");
    const token = localStorage.getItem("@educa-app/token");
    if (id == null) {
      window.location.href = "/";
    } else {
      
    }
  }, []);

  return (
    <>
      <Topo />
      <div class="main-content">
        <section class="movie-detail container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-sm-12 overflow-hidden">
                  <br />
                  <br />
                  <div class="trending-info season-info g-border">
                    <h4 class="trending-text big-title text-uppercase mt-0">
                      {props.location.state.disciplina}
                    </h4>
                    <div class="d-flex align-items-center text-white text-detail episode-name mb-0">
                      <span>{props.location.state.episodio}</span>
                    </div>
                    <p class="trending-dec w-100 mb-0">{props.location.state.titulo}</p>
                    {/* <ul class="list-inline p-0 mt-4 share-icons music-play-lists">
                      <li>
                        <span>
                          <i class="ri-add-line"></i>
                        </span>
                      </li>
                      <li>
                        <span>
                          <i class="ri-heart-fill"></i>
                        </span>
                      </li>
                    </ul> */}
                    <br/>
                    <div className="riddle_target" data-rid-id={riddleID} data-fg="#252525" data-bg="#EDEDED" style={divStyle} data-auto-scroll="true">
                      <iframe title="embed-test" style={iframeStyle} src={riddleUrl}></iframe>
                    </div>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <br />
      <br />
      <Rodape />
      <div id="back-to-top">
        <a class="top" href="#top" id="top">
          {" "}
          <i class="fa fa-angle-up"></i>{" "}
        </a>
      </div>
    </>
  );
};

export default Exercicios;
