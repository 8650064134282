import React, { useState, useEffect } from "react";
import "./registro.css";
import { Form, Alert, Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import educafilxlogo from "../../images/logo-wh.png";
import { Link, useHistory } from "react-router-dom";
import ModalApp from "../../components/ModalApp/ModalApp"
import api from "../../services/api";
import CreditCard from "../../components/Cartao/CreditCard";
import { urlbase, api_key_pagarme } from "../../services/Params";
import PulseLoader from "react-spinners/PulseLoader";
import { QRCode } from "react-qr-svg";
import moment from 'moment';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Registro = (props) => {
  const history = useHistory();
  const classes = useStyles(); 
  const [fpagto, setFpagto] = useState("");
  const [valorplano, setValorplano] = useState(0);
  const [valorplanolan, setValorplanolan] = useState(0);
  const [meuplano, setMeuplano] = useState("");
  const [plano, setPlano] = useState(null);
  const [validade, setValidade] = useState(null);
  const [retorno, setRetorno] = useState(false);
  const [retornostatus, setRetornostatus] = useState(200);
  const [retornomsg, setRetornomsg] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);

  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [sexo, se1Sexo] = useState('');
  const [dtnasc, setDtnasc] = useState('');
  const [cep, setCep] = useState('');
  const [escorigem, setEscorigem] = useState('');
  const [cursos, setCursos] = useState('');
  const [imagem, setImagem] = useState('');

  const [ipte, setIpte] = useState('');
  const [urlboleto, setUrlboleto] = useState('');
  const [urlpix, setUrlpix] = useState('');
  const [pixvalidade, setPixvalidade] = useState('');

  if (props.location.state === undefined) {
    history.push("/");
  } else {
    const { plano } = props.location.state.plano;
  }

  useEffect(() => {
    var time = new Date();
    var outraData = new Date();
    outraData.setDate(time.getDate() + 7); // Adiciona 7 dias
    setValidade(moment(outraData).format("YYYY-MM-DD"));

    if (props.location.state === undefined) {
      history.push("/");
    } else {
      if (props.location.state.plano == 1) {
        setPlano(props.location.state.plano);
        setValorplano('1990');
        setValorplanolan(19.90);
        setMeuplano("Plano mensal - R$ 19,90/mês*");
      } else {
        setPlano(props.location.state.plano);
        setValorplano('9990');
        setValorplanolan(99.90);
        setMeuplano("Plano anual - R$ 99,90/ano*");
      }

      // Checkout das transações a cada 10 segundos
      const interval = setInterval(() => {
        // console.log('Id da Transação: '+ localStorage.getItem("@educa-app/idtransacao"));
        if (localStorage.getItem("@educa-app/idtransacao") !== null){
          var requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
          
          fetch(`https://api.pagar.me/1/transactions/${localStorage.getItem("@educa-app/idtransacao")}?api_key=${api_key_pagarme}`, requestOptions)
            .then(response => response.json())
            .then(
              result => { 
              // console.log('Buscando Retorno da transação...');
              if (result.status === "paid"){
                clearInterval(interval);
                // setIdtransacao(null);
                window.location.href ='/perfil';
              }
            })
            .catch(error => {
              console.log('error', error)
            });
          } else {
            // console.log('Não encontrei o id da transacao. '+localStorage.getItem("@educa-app/idtransacao"));
          }
      }, 5000);
    }
  }, []);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalboleto, setModalBoleto] = useState(false);
  const toggleboleto = () => setModalBoleto(!modalboleto);

  const [modalpix, setModalpix] = useState(false);
  const togglepix = () => setModalpix(!modalpix);

  function setGender(event) {
    setFpagto(event.target.value);
  }

  function submit(e){
    e.preventDefault()
    const formdata = {
      first_name:nome,
      last_name:sobrenome,
      email:email,
      password:senha,
      sexo:sexo,
      datanascimento:dtnasc,
      validade:validade,
      cep:cep,
      escolaorigem:escorigem,
      plano:plano,
      cursospretendidos:"",
      isprofessor:"0"
    }

    console.log(formdata);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formdata),
    };
    setIsloading(true);
    fetch(`${urlbase}/criarconta`, requestOptions)
    .then(response => response.json())
    .then(
      result => { 
        if (result.status_code == "400") {
          console.log(result);
          setIsloading(false);
          setVisible(true);
          setRetorno(false);
          setRetornostatus(400);
          setRetornomsg(result.dados.error.msg);
          // setRetornomsg("Não foi possivel atualizar seus dados neste momento, tente novamente mais tarde.");
        } else {
          console.log(result);
          const dados =  api.get(`/loginrest?username=${email}&password=${senha}`).then((res) => {
            setIsloading(true);
            const result = res.data;
            if (result.status_code == "400") {
              setIsloading(false);
              setVisible(true);
              setRetornomsg(result.dados.error.msg);
            } else {
              setVisible(false);
              localStorage.setItem('@educa-app/id', result.dados.user_id);
              localStorage.setItem('@educa-app/user_id', result.dados.user_id);
              localStorage.setItem('@educa-app/nome', result.dados.first_name);
              localStorage.setItem('@educa-app/sobrenome', result.dados.last_name);
              localStorage.setItem('@educa-app/email', result.dados.email);
              localStorage.setItem('@educa-app/token', result.token);
              
              //history.push('/perfil');
              // window.location.href ='/perfil';
              if (fpagto === 'CC') {
                toggle();
                setIsloading(false);
              } else {
                
                if (fpagto === 'BO') {
                  const boleto = {
                    "amount": valorplano,
                    "payment_method": "boleto",
                    "customer": {
                      "external_id": localStorage.getItem("@educa-app/user_id"),
                      "name": nome+" "+sobrenome,
                      "email": email,
                      "type": "individual",
                      "country": "br",
                      "documents": [
                        {
                          "type": "cpf",
                          "number": "67415765095"
                        }
                      ],
                      "phone_numbers": [
                        "+551199999999"
                      ]
                    },
                    "billing": {
                      "address": {
                        "street": "AAAAAA",
                        "complementary": "TESTE",
                        "street_number": "666",
                        "neighborhood": "Aricanduva",
                        "city": "SP",
                        "state": "São Paulo",
                        "zipcode": "66825010",
                        "country": "br"
                      },
                      "name": "EMPRESA TESTE"
                    },
                    "items": [
                      {
                        "id": "1",
                        "title": meuplano,
                        "unit_price": valorplano,
                        "quantity": 1,
                        "tangible": true
                      }
                    ]
                  }

                  const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(boleto),
                  };
                  fetch(`https://api.pagar.me/1/transactions?api_key=${api_key_pagarme}`, requestOptions)
                  .then(response => response.json())
                  .then(
                    result => {
                      // Crio o lançamento
                      var mescompetencia = new Date();
                      var time = new Date();
                      var validadeData = new Date();
                      validadeData.setDate(time.getDate() + 7); // Adiciona 7 dias

                      var myHeaders = new Headers();
                      myHeaders.append("Content-Type", "application/json");
                      myHeaders.append("Cookie", "ci_session=eef8751e4fac055db8e14b2f0c78c625e0a3080b");

                      var raw = JSON.stringify({
                        "dtvencimento": moment(validadeData).format("YYYY-MM-DD"),
                        "competencia": moment(mescompetencia).format("MM/YYYY"),
                        "valor": valorplanolan,
                        "numparcela": 1,
                        "idformapgto": 1,
                        "users_id": localStorage.getItem("@educa-app/user_id"),
                        "planos_id": plano,
                        "idtransacao": result.id,
                        "statustransacao": result.status,
                        "link": result.boleto_url,
                        "ipte": result.boleto_barcode
                      });

                      var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                      };

                      fetch(`${urlbase}/criarlancamento`, requestOptions)
                      .then(response => response.json())
                      .then(
                        result => {

                        }
                      )
                      .catch(error => console.log('error', error));
                      
                      setIpte(result.boleto_barcode);
                      setUrlboleto(result.boleto_url);
                      toggleboleto();
                      setIsloading(false);
                    }
                  )
                } else {
                    const pix = {
                      "amount": valorplano,
                      "payment_method": "pix",
                      "pix_expiration_date": validade,
                      "pix_additional_fields": [
                        {
                          "name": meuplano,
                          "value": valorplanolan
                        }
                      ],
                      "customer": {
                        "external_id": localStorage.getItem("@educa-app/user_id"),
                        "name": nome+" "+sobrenome,
                        "email": email,
                        "type": "individual",
                        "country": "br",
                        "documents": [
                          {
                            "type": "cpf",
                            "number": "67415765095"
                          }
                        ],
                        "phone_numbers": [
                          "+551199999999"
                        ]
                      },
                      "billing": {
                        "address": {
                          "street": "AAAAAA",
                          "complementary": "TESTE",
                          "street_number": "666",
                          "neighborhood": "Aricanduva",
                          "city": "SP",
                          "state": "São Paulo",
                          "zipcode": "66825010",
                          "country": "br"
                        },
                        "name": "EMPRESA TESTE"
                      },
                      "items": [
                        {
                          "id": "1",
                          "title": meuplano,
                          "unit_price": valorplano,
                          "quantity": 1,
                          "tangible": true
                        }
                      ]
                    }
  
                    const requestOptions = {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(pix),
                    };
                    fetch(`https://api.pagar.me/1/transactions?api_key=${api_key_pagarme}`, requestOptions)
                    .then(response => response.json())
                    .then(
                      result => {
                        localStorage.setItem('@educa-app/idtransacao', result.id);
                        // Crio o lançamento
                        var mescompetencia = new Date();
                        var time = new Date();
                        var validadeData = new Date();
                        validadeData.setDate(time.getDate() + 7); // Adiciona 7 dias

                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        myHeaders.append("Cookie", "ci_session=eef8751e4fac055db8e14b2f0c78c625e0a3080b");

                        var raw = JSON.stringify({
                          "dtvencimento": moment(validadeData).format("YYYY-MM-DD"),
                          "competencia": moment(mescompetencia).format("MM/YYYY"),
                          "valor": valorplanolan,
                          "numparcela": 1,
                          "idformapgto": 2,
                          "users_id": localStorage.getItem("@educa-app/user_id"),
                          "planos_id": plano,
                          "idtransacao": result.id,
                          "statustransacao": result.status,
                          "link": result.pix_qr_code
                        });

                        var requestOptions = {
                          method: 'POST',
                          headers: myHeaders,
                          body: raw,
                          redirect: 'follow'
                        };

                        fetch(`${urlbase}/criarlancamento`, requestOptions)
                        .then(response => response.json())
                        .then(
                          result => {

                          }
                        )
                        .catch(error => console.log('error', error));
                        
                        setUrlpix(result.pix_qr_code);
                        setPixvalidade(moment(result.pix_expiration_date).format("DD/MM/YYYY"));
                        togglepix();
                        setIsloading(false);
                      }
                    ).catch(error => {
                      setRetornomsg('error: '+error);
                    });
                }
              }
            }
          }).catch(error => {
              setVisible(true);
              setRetornomsg('Não foi possível realizar o login, tente novamente mais tarde!');
          });
        }
      }
    )
  }
  
  return (
    <>
      {
          isloading ? 
          <Backdrop className={classes.backdrop} open>
          <CircularProgress color="inherit" />
        </Backdrop>  : ''
        }
      <div>
        <Modal isOpen={modal} toggle={toggle} className="perfil-modal" size="xl" centered={true}>
          <ModalHeader toggle={toggle}>Pagamento da assinatura - Cartão</ModalHeader>
          <ModalBody>
            <CreditCard nome={nome+" "+sobrenome} userid={localStorage.getItem("@educa-app/user_id")} email={email} valorlan={valorplanolan} valor={valorplano} plano={meuplano} planoid={plano}/>
          </ModalBody>
        </Modal>
      </div>

      <div>
        <Modal isOpen={modalboleto} toggle={toggleboleto} className="perfil-modal" size="xl" centered={true}>
          <ModalHeader toggle={toggleboleto}>Pagamento da assinatura - Boleto</ModalHeader>
          <ModalBody>
            <input type="text" value={ipte} style={{ marginBottom: "20px", textAlign: "center", fontSize: "18px", fontWeight: 600 }}/>
            <a href={urlpix} target="_blank" class="btn btn-hover  my-2" style={{ width: "100%" }} ><i class="fa fa-print" aria-hidden="true"></i> Imprimir Boleto</a>
          </ModalBody>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modalpix} toggle={togglepix} className="perfil-modal" size="xl" centered={true}>
          <ModalHeader toggle={togglepix}>Pagamento da assinatura - Pix</ModalHeader>
          <ModalBody>
            <div style={{ display: 'flex', justifyContent: 'center'}}>
              <div class="row">
                
                <QRCode
                    bgColor="#101010"
                    fgColor="#FFF"
                    level="Q"
                    style={{ width: 210 }}
                    value={urlpix}
                />
              </div>
            </div>
            <span style={{ marginTop: "20px", marginBottom: "20px", width: "100%", textAlign: "center", fontSize: "18px", fontWeight: 600 }}>Validade até {pixvalidade}</span>
            
          </ModalBody>
        </Modal>
      </div>
      
      <section class="sign-in-page">
        <div id="menu">
          <div id="left">
            <Link to="/">  
                <img src={educafilxlogo} alt="logo" />
            </Link>
          </div>
          <div id="right">
            {/* <Link to="" className="registro-link-menu">Sair</Link> */}
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-center align-items-center height-self-center">
            <div class="col-lg-7 col-md-12 align-self-center">
              <div class="sign-user_card ">
                <div class="sign-in-page-data">
                  <div class="sign-in-from w-100 m-auto">
                    <h3 class="mb-3 text-center">Faltam só mais alguns passos!</h3><br/>
                    { retornostatus == 200 ?
                      <Alert color="danger" isOpen={visible} toggle={onDismiss}>
                        {retornomsg}
                      </Alert>
                    : 
                      <Alert color="danger" isOpen={visible} toggle={onDismiss}>
                        {retornomsg}
                      </Alert>
                    }
                    <Form onSubmit={(e)=>submit(e)}>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>E-mail</label>
                            <Input
                              type="email"
                              class="form-control mb-0"
                              id="exampleInputEmail3"
                              placeholder="Entre com seu e-mail"
                              autocomplete="off"
                              value={email}
                              onChange={ (e) => setEmail(e.target.value) }
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <Label>Primeiro Nome</Label>
                            <Input
                              type="text"
                              class="form-control mb-0"
                              id="exampleInputEmail2"
                              placeholder="Primeiro Nome"
                              autocomplete="off"
                              value={nome}
                              onChange={ (e) => setNome(e.target.value) }
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <Label>Sobrenome</Label>
                            <Input
                              type="text"
                              class="form-control mb-0"
                              id="exampleInputEmail3"
                              placeholder="Sobrenome"
                              autocomplete="off"
                              value={sobrenome}
                              onChange={ (e) => setSobrenome(e.target.value) }
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <Label>Senha</Label>
                            <Input
                              type="password"
                              class="form-control mb-0"
                              id="exampleInputPassword2"
                              placeholder="Senha"
                              value={senha}
                              onChange={ (e) => setSenha(e.target.value) }
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <Label>Repita a Senha</Label>
                            <Input
                              type="password"
                              class="form-control mb-0"
                              id="exampleInputPassword2"
                              placeholder="Senha"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <h3 class="mb-3 text">{meuplano}</h3>
                      <div onChange={setGender.bind(this)}>
                        <div class="custom-control custom-radio mt-2">
                          <Label check>
                            <Input
                              type="radio"
                              name="formapgto"
                              className="custom-control-input"
                              value="BO"
                              required
                            />{" "}
                            <span class="custom-control-label">
                              Boleto Bancário
                            </span>
                          </Label>
                        </div>
                        <div class="custom-control custom-radio mt-2">
                          <Label check>
                            <Input
                              type="radio"
                              name="formapgto"
                              className="custom-control-input"
                              value="PIX"
                              required
                            />{" "}
                            <span class="custom-control-label">
                              Pix
                            </span>
                          </Label>
                        </div>
                        <div class="custom-control custom-radio mt-2">
                          <Label check>
                            <Input
                              type="radio"
                              name="formapgto"
                              className="custom-control-input"
                              value="CC"
                              required
                            />{" "}
                            <span class="custom-control-label">
                              Cartão de Crédito
                            </span>
                          </Label>
                        </div>
                      </div>
                      <div clas="row">
                        {/* <CreditCard /> */}
                      </div>
                      <button type="submit" className="btn btn-hover my-2" style={{ width: "100%" }} >
                        {isloading == false ?
                            "ASSINAR"
                          : 
                            <PulseLoader color={"#fff"} loading={true} size={5} />
                        }
                      </button>
                    </Form>
                  </div>
                </div>
                <div class="mt-3">
                  <div class="d-flex justify-content-center links">
                    Já tem conta?{" "}
                    <Link to="/entrar" class="text-primary ml-2">
                      Entrar
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Registro;