import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./aluno.css";
import p1 from "../../images/parallax/p1.jpg";
import matematica from "../../images/parallax/matematica.png";
import p3 from "../../images/parallax/p3.jpg";
import redacao from "../../images/parallax/redacao.png";
import cardfav01 from "../../images/favorite/01.jpg";
import cardfav02 from "../../images/favorite/02.jpg";
import cardfav03 from "../../images/favorite/03.jpg";
import cardfav04 from "../../images/favorite/04.jpg";
import cardfav05 from "../../images/favorite/05.jpg";
import cardup01 from "../../images/upcoming/01.jpg";
import cardup02 from "../../images/upcoming/02.jpg";
import cardup03 from "../../images/upcoming/03.jpg";
import cardup04 from "../../images/upcoming/04.jpg";
import cardup05 from "../../images/upcoming/05.jpg";
import card01 from "../../images/linguagensecodigos/01.jpg";
import card02 from "../../images/linguagensecodigos/02.jpg";
import card03 from "../../images/linguagensecodigos/03.jpg";
import card04 from "../../images/linguagensecodigos/04.jpg";
import card05 from "../../images/linguagensecodigos/05.jpg";
import card06 from "../../images/linguagensecodigos/06.jpg";
import card07 from "../../images/linguagensecodigos/07.jpg";
import { Form, Input } from "reactstrap";
import Topo from "../../components/Topo/Topo";
import Rodape from "../../components/Rodape/Rodape";
import CardAula from "../../components/Cards/Aulas/CardAula";
import Card from "../../components/Cards/Aulas/Card";
import MdDetalhes from '../../components/modaldetalhes/MdDetalhes';
import api from "../../services/api";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { post } from "jquery";
import HorizontalScroll from 'react-scroll-horizontal'

const child   = { width: `30em`, height: `100%`, color:"#121212"}
const parent  = { width: `60em`, height: `100%`}

const Aluno = (props) => {
   const {
      buttonLabel,
      className
    } = props;
  
   const [dadosch, setDadosch] = useState([]);
   const [modal, setModal] = useState(false);
   const toggle = () => setModal(!modal);
   const [scrollX, setScrollX] = useState(0);

   useEffect(() => {
      const id = localStorage.getItem("@educa-app/id");
      const token = localStorage.getItem("@educa-app/token");
      if (id == null) {
        window.location.href = "/";
      } else {
        async function getVideosCienciasHumanas(){
         //console.log(`/listaconteudoareaconhecimento?auth_token=${token}&codareaconhecimento=1`);
         const dados = await api.get(`/listaconteudoareaconhecimento?auth_token=${token}&codareaconhecimento=1`).then((res) => {
            const result = res.data;
            if (result.status_code == "400") {
              //console.log(result);
            } else {
               setDadosch(result.dados);
               //console.log(result);
            }
          }).catch(error => {
            console.log('Não foi possível carregar as informações do usuário');
          });
        }
        getVideosCienciasHumanas();
      }
    }, []);

   const handleLeftArrow = () => {
      let x = scrollX + Math.round(window.innerWidth / 2);
      if (x > 0){
         x= 0;
      }
      setScrollX(x);
   }

  return (
    <>
      <Topo />
      <section id="home" class="iq-main-slider p-0">
         <div id="home-slider" class="slider m-0 p-0">
            <div class="slide slick-bg s-bg-1">
               <div class="container-fluid position-relative h-100">
                  <div class="slider-inner h-100">
                     <div class="row align-items-center  h-100">
                        <div class="col-xl-6 col-lg-12 col-md-12">
                           <h1 class="slider-text big-title title text-uppercase" data-animation-in="fadeInLeft"
                              data-delay-in="0.6">Matemática</h1>
                           {/* <div class="d-flex align-items-center" data-animation-in="fadeInUp" data-delay-in="1">
                              <span class="badge badge-secondary p-2">+18 horas</span>
                              <span class="ml-3">2 temporada</span>
                           </div> */}
                           <p data-animation-in="fadeInUp" data-delay-in="1.2">A prova de Matemática do Exame Nacional do Ensino Médio (Enem) conta com 45 questões recheadas de gráficos, tabelas e enunciados que exigem habilidade de interpretação.
                           </p>
                           <div class="d-flex align-items-center r-mb-23" data-animation-in="fadeInUp" data-delay-in="1.2">
                              <a href="/conteudos/13"><div className="btn-assitir" ><i class="fa fa-play mr-2" aria-hidden="true"></i>Maratonar Agora</div></a>
                              {/* <MdDetalhes buttonLabel="Mais Informações" titulo="" size="xl"/> */}
                           </div>
                        </div>
                     </div>
                     {/* <div class="trailor-video">
                        <a href="/conteudos/13" class="video-open playbtn">
                           <svg xmlns="http://www.w3.org/2000/svg" 
                              x="0px" y="0px" width="80px" height="80px" viewBox="0 0 213.7 213.7"
                              enable-background="new 0 0 213.7 213.7" >
                              <polygon class='triangle' fill="none" stroke-width="7" stroke-linecap="round"
                                 stroke-linejoin="round" stroke-miterlimit="10"
                                 points="73.5,62.5 148.5,105.8 73.5,149.1 " />
                              <circle class='circle' fill="none" stroke-width="7" stroke-linecap="round"
                                 stroke-linejoin="round" stroke-miterlimit="10" cx="106.8" cy="106.8" r="103.3" />
                           </svg>
                           <span class="w-trailor">Assistir Dicas</span>
                        </a>
                     </div> */}
                  </div>
               </div>
            </div>
            <div class="slide slick-bg s-bg-2">
               <div class="container-fluid position-relative h-100">
                  <div class="slider-inner h-100">
                     <div class="row align-items-center  h-100">
                        <div class="col-xl-6 col-lg-12 col-md-12">
                           <h1 class="slider-text big-title title text-uppercase" data-animation-in="fadeInLeft">Química</h1>
                           {/* <div class="d-flex align-items-center" data-animation-in="fadeInUp" data-delay-in="0.5">
                              <span class="badge badge-secondary p-2">+16 horas</span>
                              <span class="ml-3">+10 Vídeos em 360º</span>
                           </div> */}
                           <p data-animation-in="fadeInUp" data-delay-in="0.7">A Química é uma ciência que estuda a matéria, as transformações que ocorrem com ela e as energias envolvidas nesses processsos.
                           </p>
                           <div class="d-flex align-items-center r-mb-23" data-animation-in="fadeInUp" data-delay-in="1">
                           <a href="/conteudos/10"><div className="btn-assitir" ><i class="fa fa-play mr-2" aria-hidden="true"></i>Maratonar Agora</div></a>
                              {/* <MdDetalhes buttonLabel="Mais Informações" titulo="" size="xl"/> */}
                           </div>
                        </div>
                     </div>
                     {/* <div class="trailor-video">
                        <a href="/conteudos/10" class="video-open playbtn">
                           <svg version="1.1" xmlns="http://www.w3.org/2000/svg" 
                              x="0px" y="0px" width="80px" height="80px" viewBox="0 0 213.7 213.7"
                              enable-background="new 0 0 213.7 213.7" >
                              <polygon class='triangle' fill="none" stroke-width="7" stroke-linecap="round"
                                 stroke-linejoin="round" stroke-miterlimit="10"
                                 points="73.5,62.5 148.5,105.8 73.5,149.1 " />
                              <circle class='circle' fill="none" stroke-width="7" stroke-linecap="round"
                                 stroke-linejoin="round" stroke-miterlimit="10" cx="106.8" cy="106.8" r="103.3" />
                           </svg>
                           <span class="w-trailor">Assistir Dicas</span>
                        </a>
                     </div> */}
                  </div>
               </div>
            </div>
            <div class="slide slick-bg s-bg-3">
               <div class="container-fluid position-relative h-100">
                  <div class="slider-inner h-100">
                     <div class="row align-items-center  h-100">
                        <div class="col-xl-6 col-lg-12 col-md-12">
                           <h1 class="slider-text big-title title text-uppercase" data-animation-in="fadeInLeft">Espanhol</h1>
                           {/* <div class="d-flex align-items-center" data-animation-in="fadeInUp" data-delay-in="0.5">
                              <span class="badge badge-secondary p-2">+20 horas</span>
                              <span class="ml-3">20 testes</span>
                           </div> */}
                           <p data-animation-in="fadeInUp" data-delay-in="0.7">A prova de espanhol do Enem consiste em 5 questões que integram a parte de Linguagens, Códigos e suas Tecnologias. Essas questões são elaboradas com o objetivo de avaliar os conhecimentos dos participantes no que diz respeito a interpretação de texto e estruturas linguísticas.
                           </p>
                           <div class="d-flex align-items-center r-mb-23" data-animation-in="fadeInUp" data-delay-in="1">
                           <a href="/conteudos/16"><div className="btn-assitir" ><i class="fa fa-play mr-2" aria-hidden="true"></i>Maratonar Agora</div></a>
                              {/* <MdDetalhes buttonLabel="Mais Informações" titulo="" size="xl"/> */}
                           </div>
                        </div>
                     </div>
                     {/* <div class="trailor-video">
                        <a href="/conteudos/16" class="video-open playbtn">
                           <svg version="1.1" xmlns="http://www.w3.org/2000/svg" 
                              x="0px" y="0px" width="80px" height="80px" viewBox="0 0 213.7 213.7"
                              enable-background="new 0 0 213.7 213.7" >
                              <polygon class='triangle' fill="none" stroke-width="7" stroke-linecap="round"
                                 stroke-linejoin="round" stroke-miterlimit="10"
                                 points="73.5,62.5 148.5,105.8 73.5,149.1 " />
                              <circle class='circle' fill="none" stroke-width="7" stroke-linecap="round"
                                 stroke-linejoin="round" stroke-miterlimit="10" cx="106.8" cy="106.8" r="103.3" />
                           </svg>
                           <span class="w-trailor">Assistir Dicas</span>
                        </a>
                     </div> */}
                  </div>
               </div>
            </div>
         </div>
      </section>

      <br/>

      <section id="iq-upcoming-movie">
         <div class="container-fluid">
            <div class="row">
               <div class="col-sm-12 overflow-hidden">
                  <div class="iq-main-header d-flex align-items-center justify-content-between">
                     <h4 class="main-title">Ciências Humanas e suas Tecnologias</h4>
                     {/* <a href="#" class="text-primary">Ver tudo</a> */}
                  </div>
                  <div class="upcoming-contens">
                     <ul class="favorites-slider list-inline row p-0 mb-0">
                        {/* <CardAula img={cardup02} id="4" disciplina="Geografia" descricao="Governos pós-Ditadura Militar" tempo="+20 horas" to="/conteudos/4" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img={cardup03} id="5" disciplina="Sociologia" descricao="Correntes filosóficas" tempo="+20 horas" to="/conteudos/5" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img={cardup04} id="6" disciplina="História" descricao="Estado Democrático de Direito" tempo="+20 horas" to="/conteudos/6" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img={cardup04} id="7" disciplina="Filosofia" descricao="Estado Democrático de Direito" tempo="+20 horas" to="/conteudos/7" tocurtir="#" toaddminhalista="#"/> */}
                        <CardAula img="https://api.educatecnologia.co/uploads/thumbnails/course_thumbnails/course_thumbnail_default_4.jpg" id="4" disciplina="Geografia" descricao="Governos pós-Ditadura Militar" tempo="+20 horas" to="/conteudos/4" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img="https://api.educatecnologia.co/uploads/thumbnails/course_thumbnails/course_thumbnail_default_5.jpg" id="5" disciplina="Sociologia" descricao="Correntes filosóficas" tempo="+20 horas" to="/conteudos/5" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img="https://api.educatecnologia.co/uploads/thumbnails/course_thumbnails/course_thumbnail_default_6.jpg" id="6" disciplina="História" descricao="Estado Democrático de Direito" tempo="+20 horas" to="/conteudos/6" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img="https://api.educatecnologia.co/uploads/thumbnails/course_thumbnails/course_thumbnail_default_7.jpg" id="7" disciplina="Filosofia" descricao="Estado Democrático de Direito" tempo="+20 horas" to="/conteudos/7" tocurtir="#" toaddminhalista="#"/>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section id="iq-upcoming-movie">
         <div class="container-fluid">
            <div class="row">
               <div class="col-sm-12 overflow-hidden">
                  <div class="iq-main-header d-flex align-items-center justify-content-between">
                     <h4 class="main-title">Ciências da Natureza e suas Tecnologias</h4>
                     {/* <a href="#" class="text-primary">Ver tudo</a> */}
                  </div>
                  <div class="upcoming-contens">
                     <ul class="favorites-slider list-inline row p-0 mb-0">
                        <CardAula img="https://api.educatecnologia.co/uploads/thumbnails/course_thumbnails/course_thumbnail_default_14.jpg" id="14" disciplina="Biologia" descricao="Governos pós-Ditadura Militar" tempo="+20 horas" to="/conteudos/14" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img="https://api.educatecnologia.co/uploads/thumbnails/course_thumbnails/course_thumbnail_default_10.jpg" id="10" disciplina="Química" descricao="Correntes filosóficas" tempo="+20 horas" to="/conteudos/10" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img="https://api.educatecnologia.co/uploads/thumbnails/course_thumbnails/course_thumbnail_default_12.jpg" id="12" disciplina="Física" descricao="Estado Democrático de Direito" tempo="+20 horas" to="/conteudos/12" tocurtir="#" toaddminhalista="#"/>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section id="iq-upcoming-movie">
         <div class="container-fluid">
            <div class="row">
               <div class="col-sm-12 overflow-hidden">
                  <div class="iq-main-header d-flex align-items-center justify-content-between">
                     <h4 class="main-title">Linguagens, Códigos e suas Tecnologias</h4>
                     {/* <a href="#" class="text-primary">Ver tudo</a> */}
                  </div>
                  <div class="upcoming-contens">
                     <ul class="favorites-slider list-inline row p-0 mb-0">
                        <CardAula img="https://api.educatecnologia.co/uploads/thumbnails/course_thumbnails/course_thumbnail_default_9.jpg" id="9" disciplina="Língua Portuguesa" descricao="Governos pós-Ditadura Militar" tempo="+20 horas" to="/conteudos/9" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img="https://api.educatecnologia.co/uploads/thumbnails/course_thumbnails/course_thumbnail_default_8.jpg" id="8" disciplina="Literatura" descricao="Governos pós-Ditadura Militar" tempo="+20 horas" to="/conteudos/8" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img="https://api.educatecnologia.co/uploads/thumbnails/course_thumbnails/course_thumbnail_default_11.jpg" id="11" disciplina="Inglês" descricao="Correntes filosóficas" tempo="+20 horas" to="/conteudos/11" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img="https://api.educatecnologia.co/uploads/thumbnails/course_thumbnails/course_thumbnail_default_16.jpg" id="16" disciplina="Espanhol" descricao="Estado Democrático de Direito" tempo="+20 horas" to="/conteudos/16" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img="https://api.educatecnologia.co/uploads/thumbnails/course_thumbnails/course_thumbnail_default_17.jpg" id="17" disciplina="Artes" descricao="Estado Democrático de Direito" tempo="+20 horas" to="/conteudos/17" tocurtir="#" toaddminhalista="#"/>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section id="iq-upcoming-movie">
         <div class="container-fluid">
            <div class="row">
               <div class="col-sm-12 overflow-hidden">
                  <div class="iq-main-header d-flex align-items-center justify-content-between">
                     <h4 class="main-title">Matemática e suas Tecnologias e Redação</h4>
                     {/* <a href="#" class="text-primary">Ver tudo</a> */}
                  </div>
                  <div class="upcoming-contens">
                     <ul class="favorites-slider list-inline row p-0 mb-0">
                        <CardAula img="https://api.educatecnologia.co/uploads/thumbnails/course_thumbnails/course_thumbnail_default_13.jpg" id="13" disciplina="Matemática" descricao="Correntes filosóficas" tempo="+20 horas" to="/conteudos/13" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img="https://api.educatecnologia.co/uploads/thumbnails/course_thumbnails/course_thumbnail_default_18.jpg" id="18" disciplina="Matemática" descricao="Correntes filosóficas" tempo="+20 horas" to="/conteudos/18" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img="https://api.educatecnologia.co/uploads/thumbnails/course_thumbnails/course_thumbnail_default_15.jpg" id="15" disciplina="Redação" descricao="Correntes filosóficas" tempo="+20 horas" to="/conteudos/15" tocurtir="#" toaddminhalista="#"/>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </section>
      
      {/* <section id="iq-upcoming-movie">
         <div class="container-fluid">
            <div class="row">
               <div class="col-sm-12 overflow-hidden">
                  <div class="iq-main-header d-flex align-items-center justify-content-between">
                     <h4 class="main-title">Ciências Humanas e suas Tecnologias</h4>
                     <a href="#" class="text-primary">Ver tudo</a>
                  </div>
                  <div class="upcoming-contens">
                     <ul class="favorites-slider list-inline row p-0 mb-0">
                        <CardAula img={cardup01} disciplina="Geografia" descricao="Clima e Vegetação" tempo="+20 horas" to="#" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img={cardup02} disciplina="História" descricao="Governos pós-Ditadura Militar" tempo="+20 horas" to="#" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img={cardup03} disciplina="Filosofia" descricao="Correntes filosóficas" tempo="+20 horas" to="#" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img={cardup04} disciplina="Sociologia" descricao="Estado Democrático de Direito" tempo="+20 horas" to="#" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img={cardup04} disciplina="Sociologia" descricao="Estado Democrático de Direito" tempo="+20 horas" to="#" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img={cardup04} disciplina="Sociologia" descricao="Estado Democrático de Direito" tempo="+20 horas" to="#" tocurtir="#" toaddminhalista="#"/>
                        <CardAula img={cardup04} disciplina="Sociologia" descricao="Estado Democrático de Direito" tempo="+20 horas" to="#" tocurtir="#" toaddminhalista="#"/>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </section> */}

      

      <section id="iq-suggestede" class="s-margin">
         <div class="container-fluid">
            <div class="row">
               <div class="col-sm-12 overflow-hidden">
                  <div class="iq-main-header d-flex align-items-center justify-content-between">
                  <h4 class="main-title">Destaque de hoje</h4>
                  </div>
                  <section id="parallex" class="parallax-window">
                     <h4 class="main-title topten-title">Matemática e suas Tecnologias</h4>
                     <div class="container-fluid h-100">
                        <div class="row align-items-center justify-content-center h-100 parallaxt-details">
                           <div class="col-lg-4 r-mb-23">
                              <div class="text-left">
                                 <a href="javascript:void(0);">
                                 <img src={matematica} class="img-fluid" alt="" />
                                 </a>
                                 <div class="parallax-ratting d-flex align-items-center mt-3 mb-3">
                                 </div>
                                 <div class="movie-time d-flex align-items-center mb-3">
                                    <div class="badge badge-secondary mr-3">+20 horas</div>
                                    <h6 class="text-white">2 Professores</h6>
                                 </div>
                                 <p>A prova de Matemática do Exame Nacional do Ensino Médio (Enem) conta com 45 questões recheadas de gráficos, tabelas e enunciados que exigem habilidade de interpretação.</p>
                                 <div class="parallax-buttons">
                                    <a href="/conteudos/13" class="btn btn-hover">Estude Agora</a>
                                    <a href="/conteudos/13" class="btn btn-link">+ Informações</a>
                                 </div>
                              </div>
                           </div>
                           <div class="col-lg-8">
                              <div class="parallax-img">
                                 <a href="/conteudos/13">
                                 <img src={p1} class="img-fluid w-100" alt="" />
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>
               </div>
            </div>
         </div>
      </section>      
      
      <Rodape />
      <div id="back-to-top">
        <a class="top" href="#top" id="top">
          {" "}
          <i class="fa fa-angle-up" style={{ fontSize: '30px' }}></i>{" "}
        </a>
      </div>
    </>
  );
};

export default Aluno;
