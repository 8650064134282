import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./faq.css";
import Topo from "../../components/TopoSite/Topo";
import Topologado from "../../components/Topo/Topo";
import Rodape from "../../components/Rodape/Rodape";
import AccordionCompound from "../../extras/AccordionCompound";

const Faq = () => {
  const [logado, setLogado] = useState(false);
  useEffect(() => {
    const id = localStorage.getItem("@educa-app/id");
    if (id == null) {
      setLogado(false);
    } else {
      setLogado(true);
    }
  }, []);

  return (
    <>
      { logado == true ? <Topologado /> : <Topo />}
      <div class="iq-breadcrumb-one  iq-bg-over iq-over-dark-50" 
        id="topo-background-faq"
      >  
            <div class="container-fluid"> 
                <div class="row align-items-center">
                    <div class="col-sm-12">
                        <nav aria-label="breadcrumb" class="text-center iq-breadcrumb-two">
                            <h2 class="title">Perguntas Frequentes</h2>
                            <ol class="breadcrumb main-bg">
                                <li class="breadcrumb-item"><a href="">Home</a></li> 
                                <li class="breadcrumb-item active">FAQ</li>
                            </ol>
                        </nav>
                    </div>
                </div> 
            </div>
        </div>
      <main id="main" class="site-main">
         <div class="container">
            <div class="row">
               <div class="col-lg-12 col-sm-12">
                  <AccordionCompound />
               </div>
            </div>
         </div>   
      </main>

      <Rodape />

      <div id="back-to-top">
        <a class="top" href="#top" id="top">
          {" "}
          <i class="fa fa-angle-up"></i>{" "}
        </a>
      </div>
    </>
  );
};

export default Faq;
