import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import api from "../../services/api";


const CardAula = (props) => {
  return (
    <>
      <li class="slide-item">
        <Link to={props.to}>
          <div class="block-images position-relative">
            <div class="img-box">
              <img src={props.img} class="img-fluid" alt="" />
            </div>
            <div class="block-description">
              <h6>{props.disciplina}</h6>
              <div class="movie-time d-flex align-items-center my-2">
                {/* <div class="badge badge-secondary p-1 mr-2">{props.tempo}</div> */}
                {/* <span class="text-white">{props.descricao}</span> */}
              </div>
              <div class="hover-buttons">
                <span class="btn btn-hover">
                  <i class="fa fa-play mr-1" aria-hidden="true"></i>
                  Estudar Agora
                </span>
              </div>
            </div>
            <div class="block-social-info">
              <ul class="list-inline p-0 m-0 music-play-lists">
                {/* <li>
                  <span>
                    <i class="ri-volume-mute-fill"></i>
                  </span>
                </li>
                <li>
                  <span>
                    <i class="ri-heart-fill"></i>
                  </span>
                </li>
                <li>
                  <span>
                    <i class="ri-add-line"></i>
                  </span>
                </li> */}
              </ul>
            </div>
          </div>
        </Link>
      </li>
    </>
  );
};

export default CardAula;
