import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

const Atividades = (props)  => {
    return (
        <div>
            <div class="course">
                <div class="atividades-preview">
                    <span class="txt-apostila-disciplina">{props.disciplina}</span>
                    <h2></h2>
                    <a href="#">Atividades <i class="fa fa-chevron-right" style={{ fontSize: '11px' }}></i></a>
                </div>
                <div class="course-info">
                    <div class="progress-container">
                        {/* <div class="progress"></div> */}
                        <span class="progress-text">
                            {/* 6/9 Páginas */}
                        </span>
                    </div>
                    <span class="txt-apostila">{props.tilulo}</span>
                    {/* <Link 
                        to={{
                            pathname: '/material',
                            state: { 
                                url: props.link,
                                iddisciplina: props.iddisciplina,
                                disciplina: props.disciplina,
                                titulo: props.tilulo,
                                episodio: props.episodio,
                                id: props.id
                            }
                          }}
                        class="btn-apostila"
                    >
                        Abrir
                    </Link> */}
                    {/* <a href={props.link} target="_blank" class="btn-apostila">Abrir</a> */}
                </div>
            </div>            
        </div>
    )
}
export default Atividades;